import * as _ from "lodash";

export const getUserNameFromMail = (email: string) => {
  let temp = email;
  temp = temp.replace("-ext", "");
  const split1 = temp.split("@");
  if (split1 && split1.length === 2) {
    const split2 = split1[0].split(".");
    if (split2.length === 2) {
      const firstName = _.startCase(split2[0]);
      const lastName = _.startCase(split2[1]);
      return firstName + " " + lastName;
    }
  }
  return email;
};

export function isJson(str: unknown): boolean {
  try {
    if (str === null || str === undefined) {
      return false;
    }
    JSON.parse(str as string);
  } catch (e) {
    return false;
  }
  return true;
}
