import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";

export const rtkQueryErrorLogger: Middleware =
  (_: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const errorMsg =
        "data" in action.error
          ? (action.error.data as { message: string }).message
          : action.error.message;
      console.error(errorMsg);
    }
    return next(action);
  };
