/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { Dispatch } from "redux";
import homeSlice from "../store/home.slice";

const mapStateToProps = (state: RootState) => ({
  home: { ...state.modules.home },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleAutomateQuickCreateModal: () =>
    dispatch(homeSlice.actions.toggleAutomateQuickCreateModal()),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const AutomateQuickCreateModal: React.FC<Props> = (props: Props) => {
  const AUTOMATE_QUICK_CREATE_URL = `https://digital.fr.world.socgen/cli-projects/automateRedirect/quickCreate.html?version='+ Date.now()+'" frameborder="0" scrolling="yes" style="height: 70vh`;

  return (
    <>
      <Modal
        centered
        fade={true}
        show={props.home?.isOpenAutomateQuickCreateModal ?? false}
        size="xl"
        onHide={props.toggleAutomateQuickCreateModal}
        backdrop={true}
        dialogClassName="modal-90w"
      >
        <div className="modal-header pb-2">
          <h3 className="modal-title">Automate your actions</h3>
          <button
            onClick={props.toggleAutomateQuickCreateModal}
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <Modal.Body className="pb-2 vh-100">
          <iframe
            title="Automate quick create"
            src={AUTOMATE_QUICK_CREATE_URL}
            height="95%"
            width="100%"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutomateQuickCreateModal);
