import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserInfoDTO from "datas/dtos/UserInfo.dto";
import { UserConfig } from "datas/models/User.m";
import { AuthState } from "./auth.states";

const initialState: AuthState = {
  token: "",
  loggedIn: false,
  user: {
    email: "",
    id: "",
    team: "",
    infos: undefined,
    optionsConfig: undefined,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logIn: (state, action: PayloadAction<{ email: string }>) => {
      state.user.email = action.payload.email;
      state.loggedIn = true;
    },

    setUserInfo: (state, action: PayloadAction<UserInfoDTO | undefined>) => {
      state.user.infos = action.payload;
      state.user.team = action.payload?.rc_local_sigle;
      state.user.id = action.payload?.contact_id;
    },

    logOut: (state) => {
      state.loggedIn = false;
    },

    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },

    setOptionsConfig: (state, action: PayloadAction<UserConfig>) => {
      state.user.optionsConfig = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
export const authInitialState = authSlice.getInitialState();
export default authSlice;
