import { MyAppConfiguration } from "app/config/configuration";
import { createRepository } from "common/utils/api/api";
import { fromJsonToObject } from "common/utils/api/api.helper";
import { CgvHealthDetailsDto } from "datas/dtos/CgvHealthDetails.dto";

const USED_SERVICES_NAME = ["CPR BI", "IC", "API KYC"];
const SERVICES_NAME_MAPPING = {
  "CPR BI": "C-Profit API",
  IC: "iC API",
  "API KYC": "KYC API",
};

export function getHealthCheckDetails(): Promise<CgvHealthDetailsDto[]> {
  return createRepository()
    .getFromExternalApi(`${MyAppConfiguration.cgvUrl}hc/details`)
    .then((response) => (response?.ok ? response.text() : Promise.reject()))
    .then((responseText) => {
      return fromJsonToObject<CgvHealthDetailsDto[]>(responseText);
    })
    .then((detailsDto) =>
      detailsDto
        .filter((item) => USED_SERVICES_NAME.includes(item.serviceName))
        .map((item) => ({
          ...item,
          serviceName: SERVICES_NAME_MAPPING[item.serviceName],
        })),
    )
    .catch((error) => {
      console.log(error);
      return undefined;
    });
}
