import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { Dispatch } from "redux";
import userBoardsEditorSlice from "../store/userBoardsEditor.slice";

const mapStateToProps = (state: RootState) => ({
  editor: { ...state.modules.userBoardsEditor },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeSearchUserBoards: (searchTerm: string) =>
    dispatch(
      userBoardsEditorSlice.actions.setUserBoardsSearchValue(searchTerm),
    ),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const UserBoardsModalSearch: React.FC<Props> = (props: Props) => {
  return (
    <div className="form-group m-0">
      <div className="input-group input-group-lg">
        <div className="input-icon-start">
          <em className="icon icon-md text-secondary">search</em>
        </div>

        <input
          type="search"
          className="form-control form-control-lg"
          placeholder={"Search your board"}
          value={props.editor.manageUserBoardsSearchValue}
          onChange={(e) => props.onChangeSearchUserBoards(e.target.value)}
        />
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserBoardsModalSearch);
