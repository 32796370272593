import guid from "../../guid";
import {
  CustomProperties,
  Environment,
  Level,
  LogEntry,
  Type,
} from "./logger.type";
import post from "./xhr";

const LEGACY_RELATIVE_PATH = "api/v1/logs/create";

const logUrls: { [env: string]: string } = {
  hom: `https://360-log-api-uat.fr.world.socgen/${LEGACY_RELATIVE_PATH}`,
  iso: `https://360-log-api-uat.fr.world.socgen/${LEGACY_RELATIVE_PATH}`,
  prd: `https://360-log-api.fr.world.socgen/${LEGACY_RELATIVE_PATH}`,
};

const SALES360_NAME = "Sales Analytics 360";

export class Logger {
  private customProperties: CustomProperties;
  private environment: Environment;
  private application: string;
  private logUrl: string;
  private component: string | undefined;

  constructor(application: string, env: Environment, component?: string) {
    this.application = application;
    this.environment = env;
    this.customProperties = {};
    this.logUrl = logUrls[env.toLowerCase()];
    this.component = component;
  }

  private readonly send = async (log: LogEntry): Promise<XMLHttpRequest> => {
    try {
      return await post(this.logUrl, JSON.stringify([log]));
    } catch (error) {
      window.console.log("[logger] An error occurred", error);
      return Promise.reject(error);
    }
  };

  public readonly setCustom = (name: string, value: string): Logger => {
    this.customProperties = { ...this.customProperties, ...{ [name]: value } };
    return this;
  };

  public readonly sendFunctionalLog = (
    message: string,
    component: string | undefined = undefined,
  ): void => {
    this.send({
      type: Type.FeatureData,
      correlationId: guid(),
      data: this.customProperties,
      criticity: Level.Info,
      environment: this.environment,
      timestamp: new Date(),
      application: this.application,
      name: SALES360_NAME,
      message,
      component: component ?? this.component,
    });
  };

  public readonly sendTechnicalLog = (
    message: string,
    level: Level,
    component: string | undefined = undefined,
  ): void => {
    this.send({
      type: Type.ConsoleLog,
      correlationId: guid(),
      data: this.customProperties,
      environment: this.environment,
      timestamp: new Date(),
      application: this.application,
      criticity: level,
      name: SALES360_NAME,
      message,
      component: component ?? this.component,
    });
  };

  public readonly sendErrorLog = (
    message: string,
    component: string | undefined = undefined,
  ): void => {
    this.send({
      type: Type.ConsoleLog,
      correlationId: guid(),
      data: this.customProperties,
      environment: this.environment,
      criticity: Level.Error,
      timestamp: new Date(),
      application: this.application,
      name: SALES360_NAME,
      message,
      component: component ?? this.component,
    });
  };
}

export default Logger;
