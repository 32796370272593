import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  SgwtHelpCenter,
  SgwtWidgetContextProvider,
} from "@sgwt/sgwt-widgets-react";
import MainScreen from "app/components/MainScreen";
import NotFoundPage from "common/components/ErrorPage/NotFoundPage";
import { ROUTES } from "common/constants/routes.const";
import AuthProvider from "modules/Authentication/components/AuthProvider";

const AppRouter: React.FC = () => {
  return (
    <SgwtWidgetContextProvider infrastructure="azure">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/:chapter/*" element={<MainScreen />} />
            <Route path="/" element={<MainScreen />} />
            <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
            <Route element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
        <SgwtHelpCenter
          applicationId={import.meta.env.VITE_APP_ID}
          mailSubject="User message"
        />
      </AuthProvider>
    </SgwtWidgetContextProvider>
  );
};

export default AppRouter;
