import { SGWTEventBus } from "./sgConnect";

export const getWidgetBus = (): SGWTEventBus | undefined => {
  const widgetConfiguration = window.SGWTWidgetConfiguration;
  return widgetConfiguration?.bus;
};

export const getSgConnectToken = (): Promise<string | undefined> =>
  new Promise<string | undefined>((resolve) => {
    const bus = getWidgetBus();
    return bus
      ? resolve(bus.dangerouslyGetCurrentValue("sg-connect.access-token"))
      : resolve(undefined);
  });
