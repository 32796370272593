import { getApiServiceUrl } from "app/config/getApiServiceUrl";
import _, { get } from "lodash";
import { toastifyError, toastifySuccess } from "../toasterNotifyer";
import { getErrorMessage } from "./api.helper";
import { getAuthorizationHeader } from "./api.helpers";
import {
  ApiRequest,
  FetchConfig,
  FetchError,
  FetchRetry,
  HttpRequestHeader,
  ToastMessage,
} from "./api.typing";

const MEDIA_JSON_TYPE = "application/json";
const DefaultHeader = { "Content-Type": MEDIA_JSON_TYPE, Accept: "*/*" };
export const fetchRetry: FetchRetry =
  (
    method: "GET" | "POST" | "PUT",
    credentials: "omit" | "include" = "include",
  ) =>
  (
    url: string,
    params?: string | object,
    body?: unknown,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    config: FetchConfig = { delay: 2000, reTries: 1 },
  ): Promise<Response> => {
    const defaultHeaders = body
      ? { Accept: MEDIA_JSON_TYPE, "content-type": MEDIA_JSON_TYPE }
      : { Accept: MEDIA_JSON_TYPE };

    const finalHeaders = { ...defaultHeaders, ...headers };
    const requestInit = {
      body: JSON.stringify(body),
      credentials,
      headers: finalHeaders,
      method,
    } as RequestInit;

    let urlQuery = url;
    if (params) {
      if (typeof params === "string") {
        urlQuery += params;
      } else {
        urlQuery = query(urlQuery, params);
      }
    }

    const wait = (delay: number) => {
      return new Promise((resolve) => setTimeout(resolve, delay));
    };

    let fetchErrorResponse: Response | undefined = undefined;
    const fetchErrorRequest: Partial<Request> = new Request(url, requestInit);

    return fetch(urlQuery, requestInit)
      .then((response: Response) => {
        if (
          config.onValidateResponse
            ? config.onValidateResponse(response)
            : response === undefined || response?.ok !== true
        ) {
          fetchErrorResponse = response;
          return Promise.reject(
            config.onGetError
              ? config.onGetError(response, fetchErrorRequest)
              : "Fetch (and retry) API error " + response.status,
          );
        }
        return Promise.resolve(response);
      })
      .catch((error): Promise<Response> => {
        if (config.reTries <= 0) {
          return catchFetchError<Response>(
            fetchErrorRequest,
            fetchErrorResponse,
          )(error);
        }
        return wait(config.delay).then(() =>
          fetchRetry(method, credentials)(url, params, body, headers, {
            ...config,
            reTries: config.reTries - 1,
          }),
        );
      });
  };

export const flattenObjectToQueryParams = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any },
  prefix = "",
  filterDefaultValues = false,
): string => {
  return _.flatMap(_.keys(params), (name: string) => {
    return typeof params[name] !== "object"
      ? `${prefix}${name}=${encodeURIComponent(params[name] ?? "")}`
      : params[name] instanceof Array
        ? _.castArray(params[name])
            .map((v) => `${prefix}${name}=${encodeURIComponent(v)}`)
            .join("&")
        : flattenObjectToQueryParams(params[name], `${prefix}${name}.`);
  })
    .filter((param) => param.split("=")[1] || !filterDefaultValues)
    .filter((param) => param.length > 0)
    .join("&");
};

export const query = (
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any },
  filterDefaultValues = false,
): string => {
  return `${url}?${flattenObjectToQueryParams(params, "", filterDefaultValues)}`;
};

export const catchFetchError =
  <T>(request?: Partial<Request>, response?: Partial<Response>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: any): Promise<T> => {
    const fetchError: FetchError = {
      error,
      request,
      response,
    };
    console.log(fetchError);
    return Promise.reject(fetchError);
  };

function getDefaultHeaders(body: object, includeGetDefaultHeader: boolean) {
  if (includeGetDefaultHeader) {
    return body
      ? { Accept: MEDIA_JSON_TYPE, "content-type": MEDIA_JSON_TYPE }
      : { Accept: MEDIA_JSON_TYPE };
  }
  return {};
}

export function createRequest(
  request: ApiRequest,
  includeGetDefaultHeader = true,
  config?: FetchConfig,
) {
  const { url, params, method, body } = request;
  const headers = {
    ...getDefaultHeaders(body, includeGetDefaultHeader),
    ...request.headers,
  };
  return fetchRetry(method, "include")(url, params, body, headers, config);
}

async function tryCatch(
  f: () => Promise<Response>,
  toastMessage?: ToastMessage,
  needToastifySuccess = false,
  returnAsJson = true,
  needToastifyError = true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  try {
    const result = await f();
    if (!result.ok) {
      if (result.status === 404 && needToastifyError) {
        toastifySuccess(toastMessage?.ressourceNotFoundMessage ?? "");
      } else {
        toastifyError(await getErrorMessage(toastMessage));
      }
      return result;
    }
    if (needToastifySuccess) {
      toastifySuccess(toastMessage?.successMessage ?? "");
    }
    if (returnAsJson) {
      return await result.json();
    }
    return result;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (needToastifyError) {
      toastifyError(get(toastMessage, "friendlyErrorMessage") || error);
    }
    if (error && "response" in error && error?.response?.status === 404) {
      return Promise.resolve(null);
    }
    return Promise.reject(error);
  }
}

export const createRepository = () => ({
  getFromAPI: <T>(
    endpoint: string,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    params?: object,
    body?: object,
    toastMessage?: ToastMessage,
  ): Promise<T> => {
    const getFetch = () =>
      createRequest(
        {
          method: "GET",
          headers,
          url: getApiServiceUrl() + endpoint,
          params: params,
          body: body,
        },
        true,
      );
    return tryCatch(getFetch, toastMessage);
  },
  getStreamFromAPI: (
    endpoint: string,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    params?: object,
    body?: object,
    toastMessage?: ToastMessage,
  ): Promise<Response> => {
    const getStreamFetch = () =>
      createRequest(
        {
          method: "GET",
          headers,
          url: getApiServiceUrl() + endpoint,
          params,
          body,
        },
        true,
      );
    return tryCatch(getStreamFetch, toastMessage, false, false);
  },
  postStreamFromAPI: (
    endpoint: string,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    params?: object,
    body?: object,
    toastMessage?: ToastMessage,
  ): Promise<Response> => {
    const getStreamFetch = () =>
      createRequest(
        {
          method: "POST",
          headers,
          url: getApiServiceUrl() + endpoint,
          params,
          body,
        },
        true,
      );
    return tryCatch(getStreamFetch, toastMessage, false, false);
  },
  getStreamFromSG: (
    url: string,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    params?: object,
    body?: object,
    toastMessage?: ToastMessage,
  ): Promise<Response> => {
    const getStreamFetch = () =>
      createRequest({ method: "GET", headers, url: url, params, body }, true);
    return tryCatch(getStreamFetch, toastMessage, false, false);
  },
  getFromSG: <T>(
    url: string,
    params?: object,
    body?: object,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    toastMessage?: ToastMessage,
  ): Promise<T> => {
    const getFetch = () =>
      createRequest({ method: "GET", headers, url, params, body }, true);
    return tryCatch(getFetch, toastMessage);
  },
  getFromSGWithoutToastify: <T>(url: string, params?: object): Promise<T> => {
    const getFetch = () =>
      createRequest(
        {
          method: "GET",
          headers: getAuthenticationHeader(),
          url,
          params,
          body: undefined,
        },
        true,
      );

    return tryCatch(getFetch, null, false, true, false);
  },
  postToExternalApi: (
    url: string,
    params?: object,
    body?: object,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    toastMessage?: ToastMessage,
  ): Promise<Response> => {
    const postFetch = () =>
      createRequest({ method: "POST", headers, url, params, body }, true);
    return tryCatch(postFetch, toastMessage, false, false);
  },
  putToExternalApi: (
    url: string,
    params?: object,
    body?: object,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    toastMessage?: ToastMessage,
  ): Promise<Response> => {
    const postFetch = () =>
      createRequest({ method: "PUT", headers, url, params, body }, true);
    return tryCatch(postFetch, toastMessage, false, false);
  },
  getFromExternalApi: (
    url: string,
    params?: object,
    headers: HttpRequestHeader = getAuthenticationHeader(),
    toastMessage?: ToastMessage,
    config?: FetchConfig,
  ): Promise<Response> => {
    const getFetch = () =>
      createRequest(
        { method: "GET", headers, url, params, body: undefined },
        true,
        config,
      );
    return tryCatch(getFetch, toastMessage, false, false);
  },
});

export const getHeader = (): HttpRequestHeader => ({
  ...getAuthenticationHeader(),
  ...DefaultHeader,
});

export const getAuthenticationHeader = (): HttpRequestHeader => ({
  authorization: getAuthorizationHeader() ?? "",
});
