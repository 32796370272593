import React from "react";
import { ProgressBar } from "react-bootstrap";

const MAX_PROGRESS = 100;
const MIN_PROGRESS = 0;
const PROGRESS_STEP = 25;
const PROGRESS_STEP_MS = 1000;
type Props = {
  onLoaded: () => void;
  id: string;
  start: boolean;
};

const BoardLoader: React.FC<Props> = ({ onLoaded, id, start }) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (start) {
      setInterval(() => {
        setProgress((prev) => {
          return prev + PROGRESS_STEP;
        });
      }, PROGRESS_STEP_MS);
    }
  }, [start]);

  if (progress > MAX_PROGRESS) {
    onLoaded();
  }
  const label = (
    <p className="me-3 mt-3 mb-3">{`${progress > MAX_PROGRESS ? MAX_PROGRESS : progress}%`}</p>
  );
  return (
    start && (
      <div className="h-100">
        <ProgressBar
          key={id}
          variant="dark"
          now={progress}
          min={MIN_PROGRESS}
          max={MAX_PROGRESS}
          style={{ height: 30, marginTop: 50, margin: 50, marginBottom: 20 }}
          label={label}
        />
        <span style={{ marginTop: 0, marginLeft: 60 }}>Loading...</span>
      </div>
    )
  );
};

export default BoardLoader;
