export default function guid(): string {
  const crypto = window.crypto;
  const guid =
    String(1e7) + String(-1e3) + String(-4e3) + String(-8e3) + +String(-1e11);
  return guid.replace(/[018]/g, (c) =>
    (
      Number(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
    ).toString(16),
  );
}
