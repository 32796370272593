/* eslint-disable @typescript-eslint/no-explicit-any */
import { SGWTConnectCore } from "@sgwt/connect-core";
import {
  SgwtConnectElement,
  SGWTEventBus,
} from "modules/Authentication/types/utils.typings";

/**
 * @returns the current sgwt widget bus
 * (the SGWT-Connect widget push information on it, on some specific topics)
 * for more info: https://sgwt.fr.world.socgen/widgets/widget-development/widget-bus
 */
export const getWidgetBus = (): SGWTEventBus | undefined => {
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus
    ? widgetConfiguration.bus
    : undefined;
};

/**
 * @returns current instance of the SGWT-Connect widget
 */
export const sgwtConnectComponent = (): SGWTConnectCore => {
  const sgwtConnectElement = document.head.querySelector("sgwt-connect") as any;
  return sgwtConnectElement.sgwtConnect;
};

export const getSgwtConnectElement = (): SgwtConnectElement =>
  document.head.querySelector("sgwt-connect");
export const getSgwtAccountCenterElement = (): SgwtConnectElement =>
  document.querySelector("sgwt-account-center");
