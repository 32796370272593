import * as React from "react";
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import appSlice from "app/store/app.slice";
import { RootState } from "app/store/store.states";
import { formatAndSendCustomLogWithBoardInfo } from "common/utils/logger/helpers";
import { getBoardId } from "common/utils/stringUtils";
import { DefaultBoardConfig } from "datas/models/DefaultBoardConfig.m";
import { UserConfig } from "datas/models/User.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { updateUserConfig } from "datas/services/userConfig.service";
import { authActions } from "modules/Authentication/store/auth.slice";
import { Dispatch } from "redux";
import DefaultBoardTab from "./DefaultBoardTab";
import UserBoardTab from "./UserBoardTab";

const mapStateToProps = (state: RootState) => ({
  app: { ...state.app },
  auth: { ...state.auth },
  datas: { ...state.datas },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setCurrentBoard: (board?: DefaultBoardConfig | UserBoardConfig) =>
    dispatch(appSlice.actions.setCurrentBoard(board)),
  setUserOptionsConfig: (options: UserConfig) =>
    dispatch(authActions.setOptionsConfig(options)),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { isUserBoardsLoading: boolean };

type CustomTabType = { id: string; tabType: string };

export const TabsList: React.FC<Props> = (props: Props) => {
  const [boardsTabList, setBoardsTabList] = React.useState<CustomTabType[]>([]);
  const userOptionsConfig = props.auth.user?.optionsConfig;
  const [expandedParentTabId, setexpandedParentTabId] = React.useState<
    string | null
  >(null);

  function handleOnClickBoardWithSubChapters(parentTabId: string) {
    setexpandedParentTabId((prev) =>
      prev === parentTabId ? null : parentTabId,
    );
  }

  function orderTabs(arrayToSort: CustomTabType[]): CustomTabType[] {
    if (
      userOptionsConfig?.boardsOrder &&
      userOptionsConfig?.boardsOrder?.length > 0
    ) {
      return arrayToSort.sort((a, b) => {
        const aIndex = userOptionsConfig?.boardsOrder?.indexOf(
          a.id.toLowerCase(),
        );
        const bIndex = userOptionsConfig?.boardsOrder?.indexOf(
          b.id.toLowerCase(),
        );
        if (bIndex === -1) {
          return -1;
        }
        if (aIndex === -1) {
          return 1;
        }
        return aIndex - bIndex;
      });
    }
    return arrayToSort;
  }

  function onUpdateUserConfig(newConfig: UserConfig) {
    updateUserConfig(props.auth.user.id, newConfig).then(() =>
      props.setUserOptionsConfig(newConfig),
    );
  }

  React.useEffect(() => {
    const defaultTabs =
      props.datas.defaultBoardConfigs?.map((d) => {
        return {
          id: d.tabName ?? d.name,
          tabType: "DEFAULT",
        };
      }) ?? [];

    const userTabs = userOptionsConfig
      ? (props.datas.userBoardConfigs?.map((d) => {
          return {
            id: d.tabName ?? d.name,
            tabType: "USER",
          };
        }) ?? [])
      : [];

    const orderedTabs = orderTabs([...defaultTabs, ...userTabs]);

    setBoardsTabList(orderedTabs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.datas.defaultBoardConfigs,
    props.datas.userBoardConfigs,
    userOptionsConfig,
    userOptionsConfig?.boardsOrder,
  ]);

  function handleDragEnd(result) {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newList = Array.from(boardsTabList);
    const [draggedItem] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, draggedItem);

    setBoardsTabList(newList);

    const newConfig: UserConfig = {
      ...userOptionsConfig,
      boardsOrder: newList.map((bt) => bt.id.toLowerCase()),
    };

    onUpdateUserConfig(newConfig);

    formatAndSendCustomLogWithBoardInfo(
      props.app.currentBoard,
      "Drag & Drop feature",
      "Drag & Drop a tab",
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="tabsList ">
        {(provided) => (
          <ul
            className="nav flex-column nav-underline nav-hover leftMenu2"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {boardsTabList?.map((tab, index) => {
              if (tab.tabType === "DEFAULT") {
                const defaultTab = props.datas.defaultBoardConfigs.filter(
                  (b) => b.tabName === tab.id || b.name === tab.id,
                )[0];
                return (
                  defaultTab && (
                    <Draggable key={tab.id} draggableId={tab.id} index={index}>
                      {(provided) => (
                        <li
                          className="nav-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DefaultBoardTab
                            defaultBoard={defaultTab}
                            onSelectBoard={props.setCurrentBoard}
                            dangerBadgeContent={
                              defaultTab.dangerBadge === "REQUIRED_ACTIONS"
                                ? props.datas.requiredActionsNb?.toString()
                                : defaultTab.dangerBadge
                            }
                            infoBadgeContent={defaultTab.infoBadge}
                            isExpanded={
                              defaultTab.children !== undefined
                                ? expandedParentTabId === defaultTab.name
                                : undefined
                            }
                            onClickBoardWithSubChapters={(id: string) =>
                              handleOnClickBoardWithSubChapters(id)
                            }
                          />
                        </li>
                      )}
                    </Draggable>
                  )
                );
              } else if (tab.tabType === "USER") {
                const userTab = props.datas.userBoardConfigs.filter(
                  (b) => b.tabName === tab.id || b.name === tab.id,
                )[0];
                return (
                  userTab && (
                    <Draggable key={tab.id} draggableId={tab.id} index={index}>
                      {(provided) => (
                        <li
                          className="nav-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <UserBoardTab
                            key={getBoardId(userTab)}
                            userBoard={userTab}
                            onSelectBoard={props.setCurrentBoard}
                          />
                        </li>
                      )}
                    </Draggable>
                  )
                );
              } else {
                return <></>;
              }
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>

      {props.isUserBoardsLoading && (
        <div className="ms-4">
          <div className="blinker" role="status">
            Loading...
          </div>
        </div>
      )}
    </DragDropContext>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TabsList);
