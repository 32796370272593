import * as React from "react";
import { Form } from "react-bootstrap";
import { SG_DASHBOARD_MYBOARDS_BASEURL } from "common/constants/constant";
import { getBoardId } from "common/utils/stringUtils";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { BoardEnvironment } from "datas/types/BoardConfig";

type Props = {
  userBoardConfig?: UserBoardConfig;
  onSelectUserBoardConfig: (
    userBoardConfig: UserBoardConfig,
    isSelected: boolean,
  ) => void;
  isSelected: boolean;
  icon: string;
  isDisabled: boolean;
  env?: BoardEnvironment;
};

const UserBoardConfigItem: React.FC<Props> = (props: Props) => {
  const onSelectBoard = () => {
    if (!props.isDisabled) {
      props.onSelectUserBoardConfig(props.userBoardConfig, !props.isSelected);
    }
  };

  const cssStyle = props.isDisabled
    ? { backgroundColor: "#EEEEEE" }
    : { cursor: "pointer" };

  return (
    <tr style={cssStyle}>
      <td onClick={onSelectBoard}>
        <Form.Check
          readOnly
          className="ms-4"
          type="checkbox"
          value={getBoardId(props.userBoardConfig) ?? ""}
          id={getBoardId(props.userBoardConfig) ?? ""}
          checked={props.isSelected ?? false}
          disabled={props.isDisabled ?? false}
        />
      </td>
      <td
        className="card-category m-1 text-primary text-large col-10"
        onClick={() => onSelectBoard()}
      >
        {props.userBoardConfig.tabName !== undefined &&
        props.userBoardConfig.tabName !== ""
          ? props.userBoardConfig.tabName
          : props.userBoardConfig.name}
      </td>
      <td className="card-category m-1 text-primary text-large col-10">
        <a
          href={SG_DASHBOARD_MYBOARDS_BASEURL + props.userBoardConfig.sgBoardId}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open preview<i className="icon">open_in_new</i>
        </a>
      </td>
    </tr>
  );
};
export default UserBoardConfigItem;
