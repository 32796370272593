export const SG_DASHBOARD_EXPLORE_BASEURL =
  "https://dashboard.sgmarkets.com/explore/boards/";
export const SG_DASHBOARD_MYBOARDS_BASEURL =
  "https://dashboard.sgmarkets.com/my-boards/";
export const SG_DASHBOARD_MYBOARDS_BASEURL_UAT =
  "https://sg-dashboard-uat.fr.world.socgen/my-boards/";
export const SG_DASHBOARD_ORIGIN = "https://dashboard.sgmarkets.com";
export const SG_DASHBOARD_UAT_ORIGIN =
  "https://sg-dashboard-uat.fr.world.socgen";

export const DAYS_TO_REMINDER = 30;
