/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "app/store/store.states";
import { formatAndSendCustomLogWithBoardInfo } from "common/utils/logger/helpers";
import { DefaultBoardConfig } from "datas/models/DefaultBoardConfig.m";
import { BoardConfig } from "datas/types/BoardConfig";
import { getLinkToBoard } from "modules/Board/components/helpers";
import { Dispatch } from "redux";
import homeSlice from "../store/home.slice";

type DefaultBoardProps = {
  defaultBoard: DefaultBoardConfig;
  onSelectBoard: (board: DefaultBoardConfig) => void;
  isExpanded?: boolean;
  onClickBoardWithSubChapters: (id: string) => void;
  dangerBadgeContent?: string;
  infoBadgeContent?: string[];
};

const mapStateToProps = (state: RootState) => ({
  app: { ...state.app },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCloneModal: (board: BoardConfig) =>
    dispatch(homeSlice.actions.toggleCloneModal(board)),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  DefaultBoardProps;

export const DefaultBoardTab: React.FC<Props> = (props: Props) => {
  const hasSubLevels: boolean = props.defaultBoard.children ? true : false;
  const [displayParentIcons, setDisplayParentIcons] = React.useState(false);
  const [displayChildIconsIndex, setDisplayChildIconsIndex] = React.useState<
    number | null
  >(null);

  const onDefaultBoardButtonClicked = (board: DefaultBoardConfig) => {
    formatAndSendCustomLogWithBoardInfo(
      board,
      "Chapter.DefaultBoard",
      `Click on chapter ${board.name} default board`,
    );
  };

  const isParentActiveTab =
    (isParentTab: boolean, classes?: string) =>
    ({ isActive }) => {
      if (isParentTab) {
        return `nav-link p-8px ps-16px ${classes}`;
      } else {
        return `nav-link p-8px ps-16px ${isActive ? "active" : undefined} ${classes}`;
      }
    };

  const onCloneBoardButtonClicked = (board: BoardConfig) => {
    formatAndSendCustomLogWithBoardInfo(
      board,
      `Clone.${board?.name}`,
      "Clone Board button clicked",
    );
    props.toggleCloneModal(board);
  };

  return (
    <div
      role="button"
      onClick={() => onDefaultBoardButtonClicked(props.defaultBoard)}
      tabIndex={0}
    >
      <NavLink
        className={isParentActiveTab(
          hasSubLevels,
          "d-flex flex-row justify-content-between",
        )}
        data-toggle="tooltip"
        data-placement="right"
        title={props.defaultBoard.description}
        data-original-title={props.defaultBoard.description}
        to={hasSubLevels ? undefined : getLinkToBoard(props.defaultBoard)}
        end
        onClick={
          hasSubLevels
            ? () => props.onClickBoardWithSubChapters(props.defaultBoard.name)
            : undefined
        }
        onMouseOver={() => {
          setDisplayParentIcons(true);
        }}
        onMouseOut={() => {
          setDisplayParentIcons(false);
        }}
      >
        <div>
          <i className="icon icon-sm pe-2">{props.defaultBoard.icon}</i>
          {props.defaultBoard.tabName !== undefined &&
          props.defaultBoard.tabName !== ""
            ? props.defaultBoard.tabName
            : props.defaultBoard.name}
          {props.dangerBadgeContent && props.dangerBadgeContent !== "0" && (
            <small className="ms-2 badge badge-sm rounded-pill badge-danger">
              {props.dangerBadgeContent}
            </small>
          )}

          {props.infoBadgeContent &&
            props.infoBadgeContent.map((content) => (
              <small
                className="ms-2 badge badge-sm rounded-pill badge-info"
                key={content}
              >
                {content}
              </small>
            ))}
        </div>
        <div className="d-flex align-items-center">
          <i
            style={{ cursor: "grab" }}
            className={`icon icon-sm ${displayParentIcons ? "" : "d-none"}`}
          >
            drag_indicator
          </i>

          {!hasSubLevels && (
            <i
              className={`icon icon-sm ${displayParentIcons ? "" : "d-none"}`}
              title="Clone this board"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onCloneBoardButtonClicked(props.defaultBoard);
              }}
            >
              content_copy
            </i>
          )}
        </div>
      </NavLink>
      {hasSubLevels && props.isExpanded && (
        <Collapse in={props.isExpanded}>
          <ol className={"list-group list-group-flush"}>
            {props.defaultBoard.children?.map((childrenBoard, i) => (
              <li className="nav-item ps-4" key={childrenBoard.name}>
                <div
                  role="button"
                  tabIndex={i}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDefaultBoardButtonClicked({
                      ...childrenBoard,
                      teamProfileId: props.defaultBoard.teamProfileId,
                    } as DefaultBoardConfig);
                  }}
                >
                  <NavLink
                    className={isParentActiveTab(
                      false,
                      "d-flex flex-row justify-content-between",
                    )}
                    data-toggle="tooltip"
                    data-placement="right"
                    title={childrenBoard.description}
                    data-original-title={childrenBoard.description}
                    to={getLinkToBoard(childrenBoard)}
                    end
                    onMouseOver={() => {
                      setDisplayChildIconsIndex(i);
                    }}
                    onMouseOut={() => {
                      setDisplayChildIconsIndex(null);
                    }}
                  >
                    <div>
                      {childrenBoard !== undefined &&
                      childrenBoard.tabName &&
                      childrenBoard.tabName !== ""
                        ? childrenBoard.tabName
                        : childrenBoard.name}
                      {childrenBoard.infoBadge &&
                        childrenBoard.infoBadge.map((content) => (
                          <small
                            className="ms-2 badge badge-sm rounded-pill badge-info"
                            key={content}
                          >
                            {content}
                          </small>
                        ))}
                    </div>

                    <div className="d-flex align-items-center">
                      <i
                        className={`icon icon-sm ${displayChildIconsIndex === i ? "" : "d-none"}`}
                        title="Clone this board"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onCloneBoardButtonClicked(childrenBoard);
                        }}
                      >
                        content_copy
                      </i>
                    </div>
                  </NavLink>
                </div>
              </li>
            ))}
          </ol>
        </Collapse>
      )}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultBoardTab);
