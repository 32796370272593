import { MyAppConfiguration } from "app/config/configuration";
import { getApiServiceUrl } from "app/config/getApiServiceUrl";
import ENDPOINTS from "common/constants/endpoints.const";
import { createRepository } from "common/utils/api/api";
import { fromJsonToObject } from "common/utils/api/api.helper";
import { FetchConfig } from "common/utils/api/api.typing";
import { logger } from "common/utils/logger";
import { getUserNameFromMail } from "common/utils/stringify";
import { SgBoardsV2DTO } from "datas/dtos/SgBoardsV2.dto";
import UserBoardConfigDTO, {
  UserBoardConfigDtoFromDB,
} from "datas/dtos/UserBoardConfig.dto";
import Dataset from "datas/models/Dataset.m";
import { User } from "datas/models/User.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import {
  getUserBoardDtoFromUserBoard,
  getUserBoardFromUserBoardDto,
} from "datas/utils/Boards";
import _ from "lodash";
import { getSgBoardById } from "./SgBoards";

export const getUserBoardConfigsId = (user: User) =>
  `${ENDPOINTS.UserBoardConfigs}_${getUserNameFromMail(user.email).replace(" ", "_")}_${user.id}`;

export const getAllDatasets = (): Promise<Dataset[]> => {
  let url = getApiServiceUrl();
  url = url.substring(0, url.length - 1);

  return createRepository()
    .getStreamFromSG(url)
    .then((response) => response.text())
    .then((response) => JSON.parse(response));
};

function getBoardNameInDashboard(
  registeredLambdaBoard: UserBoardConfigDTO,
  sgDashboardBoards: SgBoardsV2DTO[],
): string {
  return sgDashboardBoards
    ?.filter((b) => b?.id)
    ?.find((b) => b.id?.toString() === registeredLambdaBoard.sgBoardId)?.name;
}

function getUserBoardsFromSgDashboard(
  registeredUserBoards: UserBoardConfigDTO[],
  user: User,
) {
  const promises = registeredUserBoards.map((b) =>
    getSgBoardById(b.sgBoardId, b.env),
  );
  let shouldUpdateLambda = false;
  return Promise.all(promises)
    .then((responses) => responses.flat())
    .then((sgDashboardBoardsList) =>
      registeredUserBoards.map((board) => {
        if (
          sgDashboardBoardsList.some(
            (sgBoard) => sgBoard?.id.toString() === board.sgBoardId.toString(),
          )
        ) {
          return getUserBoardFromUserBoardDto(
            board,
            getBoardNameInDashboard(
              board,
              sgDashboardBoardsList.filter((p) => p),
            ),
          );
        }
        shouldUpdateLambda = true;
        return undefined;
      }),
    )
    .then((userBoardsList) => {
      if (shouldUpdateLambda) {
        putUserBoards(user.id, userBoardsList);
      }
      return userBoardsList;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function filterOnlyUserBoards(
  userBoards: UserBoardConfig[],
  user: User,
): UserBoardConfig[] {
  return userBoards
    .filter((b) => b)
    .filter((b) => b.userEmail === user.email && b.userId === user.id);
}

export function getUserBoards(user: User): Promise<UserBoardConfig[]> {
  logger().sendFunctionalLog("GET user boards from lambda");

  return createRepository()
    .getStreamFromAPI(getUserBoardConfigsId(user))
    .then(async (response) => {
      const r = await response?.text();
      return fromJsonToObject<UserBoardConfig[]>(r);
    })
    .then((userBoards) => getUserBoardsFromSgDashboard(userBoards, user))
    .then((userBoards) => filterOnlyUserBoards(userBoards, user))
    .then((response) => _.orderBy(response, "position"))
    .catch((error) => {
      console.log(error);
      return [];
    });
}

const fetchConfig: FetchConfig = {
  delay: 2000,
  reTries: 1,
  onValidateResponse: (resp: Response): boolean => {
    return !(resp?.ok || resp?.status === 404); // //false if it's not an error
  },
};

export function getUserBoardsFromDB(user: User): Promise<UserBoardConfig[]> {
  return createRepository()
    .getFromExternalApi(
      `${MyAppConfiguration.cgvUrl}api/v2/sales360/userBoards/${user.id}`,
      undefined,
      undefined,
      undefined,
      fetchConfig,
    )
    .then((response) =>
      response?.ok ? response.text() : Promise.reject(response),
    )
    .then((responseText) => {
      const dtoFromDb =
        fromJsonToObject<UserBoardConfigDtoFromDB>(responseText);
      return JSON.parse(dtoFromDb.boardsList) as UserBoardConfigDTO[];
    })
    .then((userBoards) => getUserBoardsFromSgDashboard(userBoards, user))
    .then((userBoards) => filterOnlyUserBoards(userBoards, user))
    .then((response) => _.orderBy(response, "position"))
    .catch((error) => {
      console.log(error);
      if (error?.status === 404) {
        return undefined;
      }
      return [];
    });
}

export function putUserBoards(
  userId: string,
  userBoards: UserBoardConfig[],
): Promise<void> {
  const dtos = userBoards
    .filter((board) => board !== undefined)
    .map((board) => getUserBoardDtoFromUserBoard(board));

  logger()
    .setCustom("boardsId", dtos.map((dto) => dto.sgBoardId).join(","))
    .sendFunctionalLog(`UPDATE user boards for userId ${userId}`);

  return createRepository()
    .putToExternalApi(
      `${MyAppConfiguration.cgvUrl}api/v2/sales360/userBoards/${userId}`,
      undefined,
      {
        boardsList: JSON.stringify(dtos),
      },
    )
    .then((response) => (response?.ok ? response.text() : Promise.reject()))
    .catch((error) => {
      console.log(error);
      return undefined;
    });
}
