import * as React from "react";

export interface Props {
  isVisible?: boolean;
  message?: string;
}

export const Loading: React.FunctionComponent<Props> = ({
  isVisible = true,
  message = "Loading...",
}: Props) =>
  isVisible ? (
    <div className="spinner-grow spinner-grow-sm" role="status">
      {message}
    </div>
  ) : null;
