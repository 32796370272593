import * as React from "react";
import { Form } from "react-bootstrap";
import { SG_DASHBOARD_MYBOARDS_BASEURL } from "common/constants/constant";
import { SgBoardContentDTO } from "datas/dtos/SgBoardsV3.dto";

type Props = {
  sgBoard: SgBoardContentDTO;
  onSelectSgBoard: (
    sgBoard: SgBoardContentDTO,
    icon: string,
    isSelected: boolean,
  ) => void;
  isSelected: boolean;
  icon: string;
  isDisabled: boolean;
};

export const SgBoardItem: React.FC<Props> = (props: Props) => {
  const onSelectBoard = (isSelected: boolean) => {
    if (!props.isDisabled) {
      props.onSelectSgBoard(props.sgBoard, props.icon, isSelected);
    }
  };

  const cssStyle = props.isDisabled
    ? { backgroundColor: "#EEEEEE" }
    : { cursor: "pointer" };

  return (
    <tr style={cssStyle}>
      <td onClick={() => onSelectBoard(!props.isSelected)}>
        <Form.Check
          readOnly
          className="ms-4"
          type="checkbox"
          value={props.sgBoard.id ?? ""}
          id={props.sgBoard.id?.toString() ?? ""}
          checked={props.isSelected ?? false}
          disabled={props.isDisabled ?? false}
        />
      </td>
      <td
        className="card-category m-1 text-primary text-large col-10"
        onClick={() => onSelectBoard(!props.isSelected)}
      >
        {props.sgBoard.name}
      </td>
      <td className="card-category m-1 text-primary text-large col-10">
        <a
          href={SG_DASHBOARD_MYBOARDS_BASEURL + props.sgBoard.id}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open preview<i className="icon">open_in_new</i>
        </a>
      </td>
    </tr>
  );
};

export default SgBoardItem;
