import * as React from "react";
import { Provider } from "react-redux";
import { store } from "app/store/store";
import { ErrorHandler } from "common/components/ErrorHandler/ErrorHandler";
import AppRouter from "./App.router";

export const App: React.FC = () => {
  return (
    <>
      <Provider store={store}>
        <ErrorHandler>
          <AppRouter />
        </ErrorHandler>
      </Provider>
    </>
  );
};
