import * as React from "react";

const useTimer = (
  seconds: number,
): { isTimedOut: boolean; startTimer: () => void; resetTimer: () => void } => {
  const [isTimedOut, setIsTimedOut] = React.useState(false);
  const [timerStarted, setTimerStarted] = React.useState(false);
  const timeoutRef = React.useRef(null);

  const startTimer = React.useCallback(() => {
    if (!timerStarted) {
      setTimerStarted(true);
      timeoutRef.current = setTimeout(() => {
        setIsTimedOut(true);
      }, seconds * 1000);
    }
  }, []);

  const resetTimer = React.useCallback(() => {
    setTimerStarted(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsTimedOut(false);
  }, []);
  return { isTimedOut, startTimer, resetTimer };
};

export default useTimer;
