export const BUS_TOPIC_GLOBALLANGUAGE = "global.language";
export const BUS_TOPIC_SGCONNECT_ACCESSTOKEN = "sg-connect.access-token";
export const BUS_TOPIC_SGCONNECT_USERCONNECTION = "sg-connect.user-connection";
export const BUS_TOPIC_SGCONNECT_USERINFO = "sg-connect.user-info";
export const BUS_TOPIC_SGCONNECT_GRANTEDSCOPE = "sg-connect.granted-scope";

type BusEventCallback<T> = (payload: T | undefined) => void;
type SubscriptionHandle = unknown;

export interface IUserConnection {
  claims: object;
  connected: boolean;
  mail: string;
}

export interface UserInfo {
  sub: string;
  zoneinfo: string;
  postal_country: string;
  mail: string;
  igg: string;
  last_name: string;
  login_ad: string;
  company_bdr_name: string;
  given_name: string;
  locale: string;
  contact_id: string;
  sgconnect_id: string;
  user_authorization: unknown[];
  rc_local_sigle: string;
  sesame_id: string;
  user_bdr_id: string;
  company_bdr_level: string;
  name: string;
  is_sg_group_user: string;
  family_name: string;
  first_name: string;
  company_bdr_id: string;
  preferred_language: string;
  origin_network: string;
  auth_level: string;
}

export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined;
  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;
  unsubscribe(handle: SubscriptionHandle): void;
}

export function getWidgetBus(): SgWidgetsBus | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus
    ? widgetConfiguration.bus
    : null;
}

export function getWidget<T extends HTMLElement>(tagName: string) {
  return document.querySelector(tagName) as T;
}

export function getAuthorizationHeader(): string | undefined {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<string>(
      BUS_TOPIC_SGCONNECT_ACCESSTOKEN,
    );
  }
  return undefined;
}

export const getUserInfo = (): UserInfo | undefined => {
  const bus = getWidgetBus();
  return bus
    ? bus.dangerouslyGetCurrentValue(BUS_TOPIC_SGCONNECT_USERINFO)
    : undefined;
};
