import * as React from "react";
import { Form } from "react-bootstrap";

type Props = {
  isAlwaysMirrorActive: boolean;
  onAlwaysMirrorChange: () => void;
};

export const AlwaysMirrorCheckbox: React.FC<Props> = (props: Props) => {
  return (
    <Form.Check
      style={{ fontWeight: "bold" }}
      className="ms-3"
      type="checkbox"
      id="always-mirror-checkbox"
      label="Select all and always mirror all of your personal boards from SGM Dashboard in the left-side menu"
      checked={props.isAlwaysMirrorActive ?? false}
      onChange={props.onAlwaysMirrorChange}
    />
  );
};

export default AlwaysMirrorCheckbox;
