import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import appSlice from "app/store/app.slice";
import { RootState } from "app/store/store.states";
import { FeedbackButton } from "common/components/Buttons/FeedbackButton";
import { IconChevronLeft, IconChevronRight } from "common/components/Icon/Icon";
import { formatAndSendCustomLogWithBoardInfo } from "common/utils/logger/helpers";
import { getBoardId } from "common/utils/stringUtils";
import { DefaultBoardConfig } from "datas/models/DefaultBoardConfig.m";
import { UserConfig } from "datas/models/User.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import {
  AllBoardsRef,
  BoardConfig,
  BoardRef,
  BoardRefDictionary,
} from "datas/types/BoardConfig";
import { debounce } from "lodash";
import { authActions } from "modules/Authentication/store/auth.slice";
import BoardScreen from "modules/Board/components/BoardScreen";
import {
  getActiveBoard,
  hasChildrenBoards,
} from "modules/Board/components/helpers";
import userBoardsEditorSlice from "modules/UserBoardsEditor/store/userBoardsEditor.slice";
import { Dispatch } from "redux";
import { NotFoundChapterModal } from "./NotFoundChapiterModal";
import TabsList from "./TabsList";

const mapStateToProps = (state: RootState) => ({
  app: { ...state.app },
  datas: { ...state.datas },
  editor: { ...state.modules.userBoardsEditor },
  auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setCurrentBoard: (board?: DefaultBoardConfig | UserBoardConfig) =>
    dispatch(appSlice.actions.setCurrentBoard(board)),
  toggleUserBoardsModal: () =>
    dispatch(userBoardsEditorSlice.actions.toggleUserBoardsModal()),
  setUserOptionsConfig: (options: UserConfig) =>
    dispatch(authActions.setOptionsConfig(options)),
});

const isLeftSideMenuVisibleKey = "sales360.isLeftSideMenuVisible";
const isLeftSideMenuVisibleDefaultValue =
  !localStorage?.getItem(isLeftSideMenuVisibleKey) ||
  localStorage?.getItem(isLeftSideMenuVisibleKey) === "true";

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { isUserBoardsLoading: boolean };

export const HomeBoards = React.forwardRef<AllBoardsRef, Props>(
  ({ isUserBoardsLoading, setCurrentBoard, ...props }, ref) => {
    const boards = [
      ...props.datas.defaultBoardConfigs,
      ...props.datas.userBoardConfigs,
    ];
    const [notFoundChapter, setNotFoundChapter] = React.useState(false);
    const navigate = useNavigate();
    const refs: BoardRefDictionary = {};
    const location = useLocation();
    const [isLeftSideMenuVisible, setIsLeftSideMenuVisible] = React.useState(
      isLeftSideMenuVisibleDefaultValue,
    );

    React.useEffect(() => {
      const activeBoard = getActiveBoard(
        boards,
        location.pathname.substring(1),
      ); //substring to remove the first slash
      if (activeBoard) {
        setCurrentBoard(activeBoard);
      } else if (!activeBoard && !isUserBoardsLoading) {
        navigate(`/${boards[0]?.sgBoardId}`);
        setNotFoundChapter(true);
      }
    }, [isUserBoardsLoading, setCurrentBoard, location.pathname]);

    const initRef = (board) =>
      (refs[getBoardId(board)] = React.createRef<BoardRef>());

    boards.forEach((board) =>
      hasChildrenBoards(board)
        ? (board as DefaultBoardConfig).children?.forEach((childrenBoard) =>
            initRef(childrenBoard),
          )
        : initRef(board),
    );

    React.useImperativeHandle(ref, () => {
      const currentB = refs[getBoardId(props.app.currentBoard)] ?? undefined;

      return {
        boards: refs,
        currentBoard: currentB?.current.iframe && currentB,
      };
    });

    const [_, setDimensions] = React.useState({
      mainMinHeight: window.innerHeight - 150,
    });
    const handleResize = () =>
      setDimensions({ mainMinHeight: window.innerHeight - 150 });

    React.useEffect(() => {
      const debouncedHandleResize = debounce(handleResize, 500);
      window.addEventListener("resize", debouncedHandleResize);
      return () => window.removeEventListener("resize", debouncedHandleResize);
    });

    const onCustomizeButtonClicked = () => {
      formatAndSendCustomLogWithBoardInfo(
        props.app.currentBoard,
        "Customize",
        "Customize button clicked",
      );
      props.toggleUserBoardsModal();
    };

    function isActiveBoard(
      board: UserBoardConfig | DefaultBoardConfig | BoardConfig,
    ): boolean {
      return getBoardId(board) === getBoardId(props.app.currentBoard);
    }

    function onOpenCloseMenuButtonClicked() {
      formatAndSendCustomLogWithBoardInfo(
        props.app.currentBoard,
        "OpenCloseButton",
        "Open / Close menu button clicked",
      );

      localStorage?.setItem(
        isLeftSideMenuVisibleKey,
        (!isLeftSideMenuVisible).toString(),
      );
      setIsLeftSideMenuVisible(!isLeftSideMenuVisible);
    }

    return (
      <>
        {notFoundChapter && (
          <NotFoundChapterModal defaultBoard={boards[0]?.name} />
        )}
        <div className="d-flex content">
          <div className="h-100 flex-row">
            <div
              className={`h-100 d-flex flex-column leftMenuFixedSize justify-content-start ${isLeftSideMenuVisible ? "" : "d-none"}`}
            >
              <div className="leftMenu">
                <TabsList isUserBoardsLoading={isUserBoardsLoading} />
              </div>
              <button
                onClick={onCustomizeButtonClicked}
                className="btn btn-default mt-3 mx-2"
              >
                <i className="icon icon-md me-2">settings</i> Customize
              </button>
              <FeedbackButton />
            </div>
          </div>

          <div className="mainScreen container w-100 mw-100 m-0 p-0 border bg-lvl2 d-flex">
            <button
              className="openbtn ms-1 p-1 bg-white text-secondary"
              data-toggle="tooltip"
              data-placement="right"
              title="Close / Open menu"
              // left: 246px is for css visual with leftMenu 250px
              style={{ left: isLeftSideMenuVisible ? "246px" : "0px" }}
              onClick={onOpenCloseMenuButtonClicked}
            >
              {isLeftSideMenuVisible ? (
                <IconChevronLeft />
              ) : (
                <IconChevronRight />
              )}
            </button>
            <div className="mw-100 overflow-scroll w-100 vh-100">
              {boards.map((userBoard) =>
                hasChildrenBoards(userBoard) ? (
                  (userBoard as DefaultBoardConfig).children.map(
                    (childrenBoard) => (
                      <BoardScreen
                        currentBoard={childrenBoard}
                        ref={refs[getBoardId(childrenBoard)]}
                        key={getBoardId(childrenBoard)}
                        isActiveBoard={isActiveBoard(childrenBoard)}
                      />
                    ),
                  )
                ) : (
                  <BoardScreen
                    currentBoard={userBoard}
                    ref={refs[getBoardId(userBoard)]}
                    key={getBoardId(userBoard)}
                    isActiveBoard={isActiveBoard(userBoard)}
                  />
                ),
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(HomeBoards);
