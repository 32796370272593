declare global {
  interface Window {
    globalConfiguration: AppConfiguration;
  }
}

export interface AppConfiguration {
  apiUrl: string;
  environment: string;
  scope: string;
  cgvUrl: string;
  maxxUrl: string;
}

export const MyAppConfiguration: AppConfiguration = window.globalConfiguration;
