import { MyAppConfiguration } from "app/config/configuration";
import { DAYS_TO_REMINDER } from "common/constants/constant";
import ENDPOINTS from "common/constants/endpoints.const";
import { createRepository } from "common/utils/api/api";
import { fromJsonToObject } from "common/utils/api/api.helper";
import { FetchConfig } from "common/utils/api/api.typing";
import { addDays, getDateString } from "common/utils/dateUtils";
import { logger } from "common/utils/logger";
import { UserConfigDTO } from "datas/dtos/UserConfig.dto";
import { UserConfig } from "datas/models/User.m";

const fetchConfig: FetchConfig = {
  delay: 2000,
  reTries: 1,
  onValidateResponse: (resp: Response): boolean => {
    return !(resp?.ok || resp?.status === 404); // //false if it's not an error
  },
};

export async function getAllUsersConfigs(): Promise<UserConfigDTO[]> {
  logger().sendFunctionalLog("GET all user configs from lambda");
  return createRepository()
    .getStreamFromAPI(ENDPOINTS.UserConfigs)
    .then((response) => (response.ok ? response.text() : Promise.reject()))
    .then((responseText) => {
      return fromJsonToObject<UserConfigDTO[]>(responseText);
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export function getUserConfig(userId: string): Promise<UserConfigDTO> {
  return createRepository()
    .getFromExternalApi(
      `${MyAppConfiguration.cgvUrl}api/v2/sales360/userConfiguration/${userId}`,
      undefined,
      undefined,
      undefined,
      fetchConfig,
    )
    .then((response) => (response.ok ? response.text() : Promise.reject()))
    .then((responseText) => {
      const conf = fromJsonToObject<UserConfigDTO>(responseText);
      return {
        ...conf,
        userId: userId,
      };
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
}

export function updateUserConfig(
  userId: string,
  newConf: UserConfig,
): Promise<void> {
  logger()
    .setCustom("userConfig", JSON.stringify(newConf))
    .sendFunctionalLog(`UPDATE user configuration for userId ${userId}`);

  if (!newConf.userBoardsReminderLastDate) {
    newConf.userBoardsReminderLastDate = getDateString(
      addDays(new Date(), -DAYS_TO_REMINDER),
    );
  }

  return createRepository()
    .putToExternalApi(
      `${MyAppConfiguration.cgvUrl}api/v2/sales360/userConfiguration/${userId}`,
      undefined,
      newConf,
    )
    .then((response) => (response.ok ? response.text() : Promise.reject()))
    .catch((error) => {
      console.log(error);
      return undefined;
    });
}
