export function addDays(date: Date, days: number): Date {
  if (date) {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  return undefined;
}

export function getDateString(date: Date) {
  return date?.toISOString().split("T")[0];
}
