/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { DAYS_TO_REMINDER } from "common/constants/constant";
import { addDays, getDateString } from "common/utils/dateUtils";
import { UserConfig } from "datas/models/User.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import {
  getUserBoardsFromDB,
  putUserBoards,
} from "datas/services/userBoardConfigs";
import { updateUserConfig } from "datas/services/userConfig.service";
import datasSlice from "datas/store/datas.slice";
import { getUserBoardFromSGBoard } from "datas/utils/Boards";
import { authActions } from "modules/Authentication/store/auth.slice";
import AlwaysMirrorCheckbox from "modules/UserBoardsEditor/components/AlwaysMirrorCheckbox";
import UserBoardsList from "modules/UserBoardsEditor/components/UserBoardsList";
import { Dispatch } from "redux";
import homeSlice from "../store/home.slice";

const mapStateToProps = (state: RootState) => ({
  home: { ...state.modules.home },
  auth: { ...state.auth },
  datas: { ...state.datas },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUserBoards: (boards: UserBoardConfig[]) =>
    dispatch(
      datasSlice.actions.updateAll({ type: "userBoardConfigs", datas: boards }),
    ),
  setUserOptionsConfig: (options: UserConfig) =>
    dispatch(authActions.setOptionsConfig(options)),
  toggleUserBoardsReminderModal: () =>
    dispatch(homeSlice.actions.toggleUserBoardsReminderModal()),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const UserBoardsReminderModal: React.FC<Props> = (props: Props) => {
  const { user } = props.auth;

  const [selectedUserBoardsState, setSelectedUserBoardsState] = React.useState<
    UserBoardConfig[]
  >([]);
  const [shouldDisplayModal, setShouldDisplayModal] =
    React.useState<boolean>(false);
  const [alwaysMirrorState, setAlwaysMirrorState] = React.useState(
    user.optionsConfig?.alwaysMirror,
  );

  function onUpdateUserBoards() {
    putUserBoards(user.id, selectedUserBoardsState).then(() =>
      getUserBoardsFromDB(user).then((d) => props.setUserBoards(d)),
    );
  }

  function onUpdateUserConfig(newConfig: UserConfig) {
    updateUserConfig(user.id, newConfig).then(() =>
      props.setUserOptionsConfig(newConfig),
    );
  }

  function onSaveButtonClicked() {
    onUpdateUserBoards();
    const newUserConfig: UserConfig = {
      ...user.optionsConfig,
      userBoardsReminderLastDate: getDateString(new Date()),
      alwaysMirror: alwaysMirrorState,
    };
    onUpdateUserConfig(newUserConfig);
    setShouldDisplayModal(false);
  }

  function handleSelectedBoardsChanged(selectedUserBoards: UserBoardConfig[]) {
    setSelectedUserBoardsState(selectedUserBoards);
  }

  function handleAlwaysMirrorCheckboxChange() {
    if (!alwaysMirrorState) {
      const allUserBoards = props.datas.allUserSgBoards.content.map((sgBoard) =>
        getUserBoardFromSGBoard(
          sgBoard.name,
          sgBoard.id.toString(),
          "",
          user.email,
          user.id,
        ),
      );

      setSelectedUserBoardsState(allUserBoards);
    }

    setAlwaysMirrorState(!alwaysMirrorState);
  }

  function onClose() {
    const newUserConfig: UserConfig = {
      ...user.optionsConfig,
      userBoardsReminderLastDate: getDateString(new Date()),
    };
    onUpdateUserConfig(newUserConfig);
    setShouldDisplayModal(false);
  }

  React.useEffect(() => {
    if (user.optionsConfig) {
      setAlwaysMirrorState(user.optionsConfig?.alwaysMirror);
    }
    const isLastReminderDateReset = user.optionsConfig
      ?.userBoardsReminderLastDate
      ? addDays(
          new Date(user.optionsConfig?.userBoardsReminderLastDate),
          DAYS_TO_REMINDER,
        ) < new Date()
      : false;
    const conditionToDisplay =
      props.datas.allUserSgBoards?.numberOfElements > 0 &&
      !user.optionsConfig?.alwaysMirror &&
      isLastReminderDateReset;
    setShouldDisplayModal(conditionToDisplay);
    props.toggleUserBoardsReminderModal();
  }, [user.optionsConfig]);

  return (
    <>
      <Modal
        centered
        fade={true}
        show={shouldDisplayModal}
        size="xl"
        onHide={onClose}
      >
        <div className="modal-header pb-2 ms-2">
          <h3 className="modal-title">Add your personal boards to Sales 360</h3>
          <button
            onClick={onClose}
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <Modal.Body className="pb-2">
          <div className="m-2">
            You have created {props.datas.allUserSgBoards?.numberOfElements}{" "}
            personal boards under the SGM Dashboard. You can add these to the
            list of boards in the left-side menu of Sales 360 :
            <br />
          </div>

          <div className="d-flex flex-column justify-content-start align-items-center">
            <img
              src="https://client360.sgmarkets.world.socgen/assets/img/boards.png"
              width="40%"
              alt=""
            />
          </div>
          <br />

          <div className="mb-3 pb-0 pt-3">
            <AlwaysMirrorCheckbox
              isAlwaysMirrorActive={alwaysMirrorState}
              onAlwaysMirrorChange={handleAlwaysMirrorCheckboxChange}
            />
          </div>

          <UserBoardsList
            allUserSgBoards={props.datas.allUserSgBoards}
            allUserBoards={props.datas.userBoardConfigs}
            onSelectedBoardsChanged={handleSelectedBoardsChanged}
            isDisabled={alwaysMirrorState}
            allSelected={alwaysMirrorState}
          />
        </Modal.Body>

        <Modal.Footer className="pb-2 pt-0">
          <button
            type="button"
            className="btn btn-lg btn-flat-secondary"
            data-dismiss="modal"
            onClick={onClose}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-lg btn-primary"
            data-dismiss="modal"
            data-testid="Submit"
            onClick={onSaveButtonClicked}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserBoardsReminderModal);
