import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TypeHelper from "../../common/types/utils.types";
import { DatasState } from "./datas.states";

const initialState: DatasState = {
  userBoardConfigs: [],
  defaultBoardConfigs: [],
  teamConfigs: [],
  allUserSgBoards: undefined,
  requiredActionsNb: undefined,
  healthCheckDetails: [],
};

const PRIMARY_KEYS = {
  teamConfigs: ["id"],
  defaultBoardConfigs: ["configId", "boardId"],
  userBoardConfigs: ["userId", "boardId"],
};

export const datasSlice = createSlice({
  name: "datas",
  initialState: initialState,
  reducers: {
    updateAll: <D extends keyof DatasState = keyof DatasState>(
      state,
      action: PayloadAction<{ type: keyof DatasState; datas: DatasState[D] }>,
    ) => {
      state[action.payload.type] = action.payload.datas;
    },
    update: <D extends keyof DatasState = keyof DatasState>(
      state,
      action: PayloadAction<{
        type: keyof DatasState;
        data: TypeHelper.ArrElement<DatasState[D]>;
      }>,
    ) => {
      state[action.payload.type] = [
        ...state.viewApps.filter(
          (x) =>
            !PRIMARY_KEYS[action.payload.type].every((key) => {
              return x.id === action.payload.data[key];
            }),
        ),
        action.payload,
      ];
    },

    delete: <D extends keyof DatasState = keyof DatasState>(
      state,
      action: PayloadAction<{
        dataType: keyof DatasState;
        dataValue: TypeHelper.ArrElement<DatasState[D]>;
      }>,
    ) => {
      state[action.payload.dataType] = [
        ...state.viewApps.filter(
          (x) =>
            !PRIMARY_KEYS[action.payload.dataType].every((key) => {
              return x.id === action.payload.dataValue[key];
            }),
        ),
      ];
    },
  },
});

export default datasSlice;
