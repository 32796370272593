import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { getBoardId } from "common/utils/stringUtils";
import { SgBoardContentDTO, SgBoardsV3DTO } from "datas/dtos/SgBoardsV3.dto";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { getUserBoardFromSGBoard } from "datas/utils/Boards";
import _ from "lodash";
import SgBoardItem from "./SgBoardItem";
import UserBoardConfigItem from "./UserBoardConfigItem";

const mapStateToProps = (state: RootState) => ({
  auth: { ...state.auth },
});

type Props = ReturnType<typeof mapStateToProps> & {
  allUserSgBoards: SgBoardsV3DTO;
  allUserBoards: UserBoardConfig[];
  onSelectedBoardsChanged: (selectedUserBoards: UserBoardConfig[]) => void;
  isDisabled: boolean;
  searchedTitle?: string;
  allSelected?: boolean;
};

type State = {
  allUserSgBoards?: SgBoardsV3DTO;
  selectedUserBoardsState: Map<string, UserBoardConfig>;
  initialSelectedBoardsState: Map<string, UserBoardConfig>;
};

const UserBoardsList: React.FC<Props> = (props: Props) => {
  const initSelectedUserBoardsState = () =>
    new Map<string, UserBoardConfig>(
      props.allUserBoards.map((b) => [getBoardId(b), b]),
    );

  const [state, setState] = React.useState<State>({
    allUserSgBoards: props.allUserSgBoards,
    selectedUserBoardsState: initSelectedUserBoardsState(),
    initialSelectedBoardsState: initSelectedUserBoardsState(),
  });

  const onSelectSgBoard = (
    sgBoard: SgBoardContentDTO,
    icon: string,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      setState({
        ...state,
        selectedUserBoardsState: new Map(
          state.selectedUserBoardsState.set(
            sgBoard.id.toString(),
            getUserBoardFromSGBoard(
              sgBoard.name,
              sgBoard.id.toString(),
              "",
              props.auth.user.email,
              props.auth.user.id,
              icon,
            ),
          ),
        ),
      });
    } else {
      const selectedBoard = new Map(state.selectedUserBoardsState);
      selectedBoard.delete(sgBoard.id.toString());
      setState({ ...state, selectedUserBoardsState: selectedBoard });
    }
  };

  const onSelectUserBoardConfig = (
    userBoardConfig: UserBoardConfig,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      setState({
        ...state,
        selectedUserBoardsState: new Map(
          state.selectedUserBoardsState.set(
            getBoardId(userBoardConfig),
            userBoardConfig,
          ),
        ),
      });
    } else {
      const newUserBoardConfigState = new Map(state.selectedUserBoardsState);
      if (newUserBoardConfigState.delete(getBoardId(userBoardConfig))) {
        setState({
          ...state,
          selectedUserBoardsState: newUserBoardConfigState,
        });
      }
    }
  };

  React.useEffect(() => {
    props.onSelectedBoardsChanged([...state.selectedUserBoardsState.values()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedUserBoardsState]);

  React.useEffect(() => {
    setState({
      allUserSgBoards: props.allUserSgBoards,
      selectedUserBoardsState: initSelectedUserBoardsState(),
      initialSelectedBoardsState: initSelectedUserBoardsState(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allUserSgBoards, props.allUserBoards]);

  return (
    <Table className="striped bordered hover">
      <tbody>
        {state.initialSelectedBoardsState.size > 0
          ? [...state.initialSelectedBoardsState.values()]
              .filter((initialSelectedBoard) =>
                props.searchedTitle
                  ? initialSelectedBoard.name
                      ?.toLowerCase()
                      .includes(props.searchedTitle.toLowerCase())
                  : true,
              )
              .map((userBoardConfig) => (
                <UserBoardConfigItem
                  key={getBoardId(userBoardConfig)}
                  userBoardConfig={userBoardConfig}
                  onSelectUserBoardConfig={onSelectUserBoardConfig}
                  isSelected={
                    props.allSelected
                      ? true
                      : state.selectedUserBoardsState.has(
                          getBoardId(userBoardConfig),
                        )
                  }
                  icon={
                    state.selectedUserBoardsState.get(
                      getBoardId(userBoardConfig),
                    )?.icon || ""
                  }
                  isDisabled={props.isDisabled}
                  env={
                    state.selectedUserBoardsState.get(
                      getBoardId(userBoardConfig),
                    )?.env || "prd"
                  }
                />
              ))
          : null}

        {state.allUserSgBoards
          ? _.sortBy(state.allUserSgBoards?.content, "name")
              .filter((sgBoard) =>
                props.searchedTitle
                  ? sgBoard.name
                      ?.toLowerCase()
                      .includes(props.searchedTitle.toLowerCase())
                  : true,
              )
              .filter(
                (sgBoard) =>
                  state.initialSelectedBoardsState.has(
                    sgBoard.id.toString(),
                  ) === false,
              )
              .map((sgBoard) => (
                <SgBoardItem
                  key={sgBoard.id}
                  sgBoard={sgBoard}
                  onSelectSgBoard={onSelectSgBoard}
                  isSelected={
                    props.allSelected
                      ? true
                      : state.selectedUserBoardsState.has(sgBoard.id.toString())
                  }
                  icon={
                    state.selectedUserBoardsState.get(sgBoard.id.toString())
                      ?.icon || ""
                  }
                  isDisabled={props.isDisabled}
                />
              ))
          : null}
      </tbody>
    </Table>
  );
};

export default connect(mapStateToProps)(UserBoardsList);
