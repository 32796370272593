import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BoardConfig } from "datas/types/BoardConfig";
import { HomeState } from "./home.states";

const initialState: HomeState = {
  isOpenCloneModal: false,
  exportPdfDisabled: false,
  isOpenUserBoardsReminderModal: false,
  isOpenAutomateQuickCreateModal: false,
};

export const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    toggleCloneModal: (state, action: PayloadAction<BoardConfig>) => {
      state.isOpenCloneModal = !state.isOpenCloneModal;
      state.boardToClone = action.payload;
    },
    enableExportPdf: (state) => {
      state.exportPdfDisabled = false;
    },
    disableExportPdf: (state) => {
      state.exportPdfDisabled = true;
    },
    toggleUserBoardsReminderModal: (state) => {
      state.isOpenUserBoardsReminderModal =
        !state.isOpenUserBoardsReminderModal;
    },
    toggleAutomateQuickCreateModal: (state) => {
      state.isOpenAutomateQuickCreateModal =
        !state.isOpenAutomateQuickCreateModal;
    },
  },
});

export default homeSlice;
