import { get } from "lodash";
import { ToastMessage } from "./api.typing";

export const getErrorMessage = async (toastMessage?: ToastMessage) => {
  return get(toastMessage, "friendlyErrorMessage");
};

export const fromJsonToObject = <T>(content: string): T => {
  try {
    return JSON.parse(content) as T;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
export const sgLambdaStreamToObject = <T extends any = any>(
  sgLambdaStream: string,
): T[] => {
  const data = sgLambdaStream.split("\n").map((i) => i.split(","));
  const headers = data.shift() ?? [];
  return data.map<T>((d) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    headers.forEach(
      (h, i) =>
        (obj[headers[i].replace("\r", "")] = d[i]
          ?.trim()
          .replace(/\r/g, "")
          .replace(/"/g, "")),
    );
    return obj;
  });
};
