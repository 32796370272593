import { Id, toast } from "react-toastify";
import ToastContent from "./ToastContent";

export type ToastMessage = string | JSX.Element;
const genericErrorMessage =
  "An error occured. Contact the support if the problem persists";

const toastOptions = (className?: string) => ({
  closeButton: true,
  className: `toast fade show ${className}`,
});

export const toastify = (
  message: ToastMessage,
  title: string = undefined,
  detail?: string,
) => {
  if (message || title) {
    return <ToastContent title={title} message={message} detail={detail} />;
  }
  return null;
};
export const toastifySuccess = (message: ToastMessage, title?: string) =>
  toast.success(toastify(message, title), toastOptions());
export const toastifyErrorMessage = (message: ToastMessage, title?: string) =>
  toast.error(toastify(message, title), toastOptions());
export const toastifyWarningMessage = (
  message: ToastMessage,
  title?: string,
  detail?: string,
) => toast.error(toastify(message, title, detail), toastOptions());
export const toastifyInfo = (message: ToastMessage) =>
  toast.info(toastify(message), toastOptions());

export const toastifyError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessage?: any,
  message = genericErrorMessage,
): Id => {
  const error =
    (typeof errorMessage === "string"
      ? errorMessage
      : errorMessage?.error?.message) ?? message;
  return toast.error(`Error while calling API : ${error}`, {
    autoClose: false,
  });
};
