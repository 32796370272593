import { AppState } from "app/store/store.states";
import { getBoardId, getBoardUrl } from "common/utils/stringUtils";
import { DefaultBoardConfig } from "datas/models/DefaultBoardConfig.m";
import { User } from "datas/models/User.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { DatasState } from "datas/store/datas.states";
import { BoardConfig } from "datas/types/BoardConfig";

export function getCurrentBoardId(
  app: AppState,
  datas: DatasState,
): string | undefined {
  if (app.currentBoard) {
    return getBoardId(app.currentBoard);
  } else {
    return getBoardId(datas.defaultBoardConfigs.find((x) => x));
  }
}

export function checkCurrentBoard(props) {
  return (
    getBoardId(props.currentBoard) === getCurrentBoardId(props.app, props.datas)
  );
}

export function getBoardLink(
  userBoardConfig: UserBoardConfig,
  currentUser: User,
): string {
  return userBoardConfig?.userId === currentUser.id
    ? getBoardUrl("MYBOARD", userBoardConfig, true)
    : getBoardUrl("EXPLORE", userBoardConfig, true);
}

export function hasChildrenBoards(
  board: UserBoardConfig | DefaultBoardConfig,
): boolean {
  return "children" in board && board.children !== undefined;
}

export function getActiveBoard(
  boards: (DefaultBoardConfig | UserBoardConfig)[],
  chapterId: string,
): DefaultBoardConfig | UserBoardConfig {
  let activeBoard = null;
  if (!chapterId) {
    activeBoard = boards[0];
  }
  if (chapterId) {
    const split = chapterId.split("/");
    let boardId = split[0];
    const configId = split[1];

    if (!parseInt(boardId)) {
      boardId = decodeURI(boardId);
    }

    let found = false;
    let i = 0;

    while (!found && i < boards.length) {
      activeBoard = findBoard(boards[i], boardId, configId);
      if (activeBoard) {
        found = true;
      }
      i++;
    }
  }
  return activeBoard;
}

export function isSameBoardIdAndConfigId(
  board: BoardConfig,
  boardId: string,
  configId: string,
) {
  return (
    board.sgBoardId?.toString() === boardId &&
    (board.sgBoardConfigId?.toString() === configId || !configId)
  );
}

function findBoard(
  board: UserBoardConfig | DefaultBoardConfig,
  boardId: string,
  configId: string,
): UserBoardConfig | DefaultBoardConfig {
  if (
    isSameBoardIdAndConfigId(board, boardId, configId) ||
    board.name === boardId
  ) {
    return board;
  } else {
    if (hasChildrenBoards(board)) {
      const foundBoard = (board as DefaultBoardConfig).children.find(
        (child) =>
          isSameBoardIdAndConfigId(child, boardId, configId) ||
          child.name === boardId,
      ) as DefaultBoardConfig;
      if (foundBoard) {
        return foundBoard;
      }
    }
  }
}

export function getLinkToBoard(board: DefaultBoardConfig | BoardConfig) {
  if (board?.sgBoardId) {
    return board.sgBoardConfigId
      ? `/${board.sgBoardId}/${board.sgBoardConfigId}`
      : `/${board.sgBoardId}`;
  }
  return board && "name" in board ? `/${board["name"]}` : "";
}
