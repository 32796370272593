import { UserConfigDTO } from "datas/dtos/UserConfig.dto";
import { UserConfig } from "datas/models/User.m";

export function getUserConfigFromDto(dto: UserConfigDTO): UserConfig {
  return {
    alwaysMirror: dto.alwaysMirror,
    userBoardsReminderLastDate: dto.userBoardsReminderLastDate,
    boardsOrder: dto.boardsOrder,
    betaFeatures: dto.betaFeatures,
  };
}
