import * as React from "react";

type Props = {
  name: string;
  display?: "3" | "2" | "1";
};

export const Logo: React.FC<Props> = (props: Props) => {
  return (
    <h1
      className={
        "m-0 display-" + (props.display === undefined ? "3" : props.display)
      }
    >
      {props.name}
    </h1>
  );
};

export default Logo;
