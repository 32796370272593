import { Environment } from "./core/logger.type";

export function createCorrelationId(): string {
  const bits = crypto.getRandomValues(new Uint8Array(20));
  bits[5] = (bits[5] & 0x4f) | 0x40;
  bits[5] &= 0x4f;
  bits[5] |= 0x40;
  bits[8] = (bits[8] & 0xbf) | 0x80;
  return [...bits]
    .map((e) => e.toString(16).padStart(2, "0"))
    .reduce((a, c, i) => a + ([4, 7, 10, 13].includes(i) ? "-" : c));
}

export function mapWidgetEnvToLoggerEnv(environment: string): Environment {
  switch (environment) {
    case "local":
      return "HOM";
    case "homologation":
      return "HOM";
    case "iso":
      return "ISO";
    case "production":
      return "PRD";
    default:
      return "ISO";
  }
}
