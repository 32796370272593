import * as React from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { RootState } from "app/store/store.states";
import Header from "common/components/Header/Header";
import { logger } from "common/utils/logger";
import { includes, some } from "lodash";
import HomeScreen from "modules/Home/components/HomeScreen";
import { Dispatch } from "redux";
import appSlice from "../store/app.slice";

const BLOCKING_ERRORS = ["500", "404", "403"];

const mapStateToProps = (state: RootState) => ({
  ...state.app,
  ...state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  cleanApp: () => dispatch(appSlice.actions.cleanApp()),
});

type Props = StoreStateProps & StoreDispatchProps;
type StoreStateProps = ReturnType<typeof mapStateToProps>;
type StoreDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const MainScreen: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line no-restricted-globals
  const relativePath = location.pathname;
  const isErrorPage = some(BLOCKING_ERRORS, (error) =>
    includes(relativePath, error),
  );

  React.useEffect(() => {
    logger()
      .setCustom("action", "Mainscreen.Launched")
      .sendFunctionalLog("Mainscreen launched");
  }, []);

  return (
    <>
      <header className={isErrorPage ? "d-none" : ""}>
        <Header />
      </header>

      <main
        id="main"
        role="main"
        className={`container-fluid d-flex flex-column p-0 bg-white`}
      >
        {props.loggedIn &&
        props.user.id !== undefined &&
        props.user.id !== "" ? (
          <HomeScreen />
        ) : (
          <></>
        )}

        <sgwt-splash-screen
          id={"cpr-salesanalytics-ui"}
          application-id={"cpr-salesanalytics-ui"}
          sg-connect-support="sg-connect-v2"
        />
      </main>

      <ToastContainer
        hideProgressBar={true}
        autoClose={5000}
        position="top-right"
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
