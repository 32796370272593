/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { formatAndSendCustomLogWithBoardInfo } from "common/utils/logger/helpers";
import { getBoardUrl } from "common/utils/stringUtils";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { Dispatch } from "redux";
import homeSlice from "../store/home.slice";

const mapStateToProps = (state: RootState) => ({
  app: { ...state.app },
  home: { ...state.modules.home },
  auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCloneModal: () => dispatch(homeSlice.actions.toggleCloneModal()),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const CloneModal: React.FC<Props> = (props: Props) => {
  const onOpenInSgDashboardClick = () => {
    formatAndSendCustomLogWithBoardInfo(
      props.home.boardToClone,
      `Clone.${props.home.boardToClone?.name}.OpenInSgDashboard`,
      `Clone ${props.home.boardToClone?.name} in SG Dashboard`,
    );
  };

  return !props.home.boardToClone ? (
    <></>
  ) : (
    <>
      <Modal
        centered
        fade={true}
        show={props.home.isOpenCloneModal}
        size="xl"
        onHide={props.toggleCloneModal}
      >
        <div className="modal-header pb-2">
          <h3 className="modal-title">
            <i className="icon me-1">content_copy</i>
            {(props.home.boardToClone as UserBoardConfig)?.userId
              ? "Clone personal board"
              : "Clone official board"}
          </h3>
          <button
            onClick={props.toggleCloneModal}
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <Modal.Body className="pb-2">
          <div className="m-2">
            Please follow the instructions:
            <ol>
              <li>
                Open {props.home.boardToClone?.name} in&nbsp;
                <a
                  href={getBoardUrl("EXPLORE", props.home.boardToClone)}
                  target="blank"
                  className="btn btn-sm btn-primary btn-icon-text"
                >
                  <i
                    className="icon"
                    onClick={onOpenInSgDashboardClick}
                    role="button"
                    tabIndex={0}
                  >
                    open_in_new
                  </i>{" "}
                  SG Dashboard
                </a>
              </li>
              <li>
                Click <b>Add to My boards</b>
              </li>
              <li>
                Go back to Sales Analytics 360 and click <b>Customize</b>
              </li>
              <li>
                Select your new board in list and click <b>Save</b>
              </li>
            </ol>
          </div>

          <div className="d-flex flex-column justify-content-start align-items-center">
            <img
              src="https://client360.sgmarkets.world.socgen/assets/img/cloneBoard.png"
              width="80%"
              alt=""
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-2 pt-0">
          <button
            type="button"
            className="btn btn-lg btn-flat-secondary"
            data-dismiss="modal"
            onClick={props.toggleCloneModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CloneModal);
