import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultBoardConfig } from "datas/models/DefaultBoardConfig.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { AppState } from "./store.states";

const initialState: AppState = {
  currentBoard: undefined,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    cleanApp: (state) => {
      state.currentBoard = undefined;
    },
    setCurrentBoard: (
      state,
      action: PayloadAction<DefaultBoardConfig | UserBoardConfig | undefined>,
    ) => {
      state.currentBoard = action.payload;
    },
  },
});

export default appSlice;
