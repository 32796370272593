import {
  SG_DASHBOARD_MYBOARDS_BASEURL,
  SG_DASHBOARD_MYBOARDS_BASEURL_UAT,
} from "common/constants/constant";
import UserBoardConfigDTO from "datas/dtos/UserBoardConfig.dto";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { BoardConfig, BoardEnvironment } from "datas/types/BoardConfig";

export const getUserBoardFromSGBoard = (
  sgBoardName: string,
  sgBoardId: string | undefined,
  sgBoardConfigId: string | undefined,
  userEmail: string,
  userId: string,
  icon?: string,
  tabName?: string,
  env?: BoardEnvironment,
): UserBoardConfig => {
  return {
    icon: icon || "",
    name: sgBoardName,
    position: "0",
    sgBoardConfigId: sgBoardConfigId,
    sgBoardId: sgBoardId,
    tabName: tabName,
    userEmail: userEmail,
    userId: userId,
    env,
  } as UserBoardConfig;
};

export const getBoardConfigFromSharedUrl = (url: string): BoardConfig => {
  let configId: string = "";
  const searchParams = new URLSearchParams(url);
  const paramsValues = [...searchParams.values()];
  if (paramsValues.length > 0) configId = paramsValues[0];

  const sgDashboardStartURL =
    /https:\/\/(sg-dashboard[a-z.-]*|dashboard\.sgmarkets\.com)\/explore\/boards\//;

  const isSgDashboardUrl = (url: string): boolean => {
    const result = url.match(sgDashboardStartURL);
    return result && "length" in result && result.length > 0;
  };

  const getEnv = (url: string): "uat" | "prd" => {
    return url.indexOf("-uat") >= 0 ? "uat" : "prd";
  };

  const getSgBoardIdFromUrl = (url: string): string => {
    const tempUrl = url.replace(sgDashboardStartURL, "");
    const tempUrlSplit = tempUrl.split("?");
    if (tempUrlSplit.length === 2) {
      return tempUrlSplit[0];
    }
    return "";
  };

  return {
    name: "",
    sgBoardId: isSgDashboardUrl(url) ? getSgBoardIdFromUrl(url) : undefined,
    sgBoardConfigId: isSgDashboardUrl(url) ? configId : undefined,
    icon: "",
    position: "",
    env: getEnv(url),
  } as BoardConfig;
};

export const getUserBoardFromUserBoardDto = (
  dto: UserBoardConfigDTO,
  boardName: string,
): UserBoardConfig => {
  if (dto && boardName) {
    const userBoard: UserBoardConfig = {
      icon: dto.icon || "",
      name: boardName,
      position: "0",
      sgBoardConfigId: dto.sgBoardConfigId,
      sgBoardId: dto.sgBoardId,
      tabName: dto.name,
      userEmail: dto.userEmail,
      userId: dto.userId,
      env: dto.env,
    };
    return userBoard;
  }
  return undefined;
};

export const getUserBoardDtoFromUserBoard = (
  userBoard: UserBoardConfig,
): UserBoardConfigDTO => {
  const dto: UserBoardConfigDTO = {
    icon: userBoard.icon || "",
    name: userBoard.tabName,
    position: "0",
    sgBoardConfigId: userBoard.sgBoardConfigId,
    sgBoardId: userBoard.sgBoardId,
    userEmail: userBoard.userEmail,
    userId: userBoard.userId,
    env: userBoard.env,
  };
  return dto;
};

export const getSgDashboardMyBoardsBaseURL = (
  sgBoardId: string,
  configId: string,
  fullScreenUrl: string,
  env = "prd",
): string => {
  if (!sgBoardId) {
    return "";
  }
  const url =
    env === "prd"
      ? SG_DASHBOARD_MYBOARDS_BASEURL
      : SG_DASHBOARD_MYBOARDS_BASEURL_UAT;
  return `${url}${sgBoardId}${fullScreenUrl}${configId}`;
};

export default {
  getUserBoardFromSGBoard,
  getUserBoardIdAndConfigFromSharedUrl: getBoardConfigFromSharedUrl,
  getUserBoardFromUserBoardDto,
  getUserBoardDtoFromUserBoard,
  getSgDashboardMyBoardsBaseURL,
};
