import { BoardConfig } from "datas/types/BoardConfig";
import { isEmpty, noop } from "lodash";
import { Maybe } from "maybe-monade";
import { UnknownAction } from "redux";
import { getBoardUrl } from "../stringUtils";
import {
  ActionLog,
  ActionMeta,
  AsyncActionLog,
  AsyncActionTypes,
  Level,
  LogMessage,
  Type,
} from "./core/logger.type";
import { logger } from ".";

const isActionCanBeLogged = (meta: ActionMeta, actionName: string): boolean => {
  return (
    !isEmpty(actionName) &&
    actionName.split("/").length > 0 &&
    !!meta &&
    !!meta.logger
  );
};

const getAsyncActionLogMessage = (
  name: string,
  { event, description }: AsyncActionLog,
): Maybe<LogMessage> => {
  const message = { name, feature: name.split("/")[0], event, description };
  if (
    name.indexOf(AsyncActionTypes.started) >= 0 ||
    name.indexOf(AsyncActionTypes.done) >= 0
  ) {
    return Maybe.some<LogMessage>({
      ...message,
      type: Type.FeatureData,
      level: Level.Info,
    });
  } else if (name.indexOf(AsyncActionTypes.failed) >= 0) {
    return Maybe.some<LogMessage>({
      ...message,
      type: Type.ConsoleData,
      level: Level.Error,
    });
  } else {
    return Maybe.none<LogMessage>();
  }
};

const getActionLogMessage = (
  name,
  { description, event, level, logType }: ActionLog,
): LogMessage => ({
  name,
  type: logType,
  feature: name.split("/")[0],
  event,
  description,
  level,
});

const createLogMessageFromAction = ({
  meta,
  type,
}: UnknownAction): Maybe<LogMessage> => {
  if (!isActionCanBeLogged(meta, type)) {
    return Maybe.none();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logger = (meta as any).logger;
  return Maybe.some(
    getAsyncActionLogMessage(type, logger as AsyncActionLog).getOrElse(
      getActionLogMessage(type, logger as ActionLog),
    ),
  );
};

export const logAction = (action: UnknownAction) => {
  createLogMessageFromAction(action).do((message) =>
    !isEmpty(message)
      ? logger().sendTechnicalLog(
          message.description,
          message.level,
          message.feature,
        )
      : noop,
  );
};

export const logError = (error: Error, stack: string) => {
  logger().sendErrorLog(`${error.message}${stack}`);
};

export function formatAndSendCustomLogWithBoardInfo(
  board: BoardConfig,
  actionName: string,
  message: string,
) {
  logger()
    .setCustom("boardName", board?.name)
    .setCustom("boardUrl", getBoardUrl("EXPLORE", board))
    .setCustom("boardId", board?.sgBoardId)
    .setCustom("boardConfigurationId", board?.sgBoardConfigId)
    .setCustom("action", actionName)
    .sendFunctionalLog(message);
}
