import React from "react";

export const FeedbackButton: React.FC = () => {
  return (
    <button
      className="btn btn-default mt-3 mx-2"
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const helpCenter: any = document.querySelector("sgwt-help-center");
        helpCenter?.feedback();
      }}
    >
      <i className="icon">edit</i>
      Leave feedback
    </button>
  );
};
