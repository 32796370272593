/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { getBoardId } from "common/utils/stringUtils";
import { DefaultBoardConfig } from "datas/models/DefaultBoardConfig.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { BoardConfig, BoardRef } from "datas/types/BoardConfig";
import BoardLoader from "./BoardLoader";
import { checkCurrentBoard, getBoardLink } from "./helpers";

const mapStateToProps = (state: RootState) => ({
  app: { ...state.app },
  auth: { ...state.auth },
  board: { ...state.modules.board },
  datas: { ...state.datas },
});

const DEFAULT_PAGE_WIDTH: number = 99.9;
const FIXED_PAGE_WIDTH: number = 100;
//the above constant are just to fix page blank issue when first loading any iFrame

type Props = ReturnType<typeof mapStateToProps> & {
  currentBoard: UserBoardConfig | DefaultBoardConfig | BoardConfig;
  isActiveBoard: boolean;
};

export const BoardScreen = React.forwardRef<BoardRef, Props>(
  (props: Props, ref) => {
    const { currentBoard, isActiveBoard } = props;

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [width, setWidth] = React.useState(DEFAULT_PAGE_WIDTH);
    const [hasBeenOpened, setHasBeenOpened] = React.useState(false);

    React.useEffect(() => {
      if (!isActiveBoard) {
        setWidth(DEFAULT_PAGE_WIDTH);
      } else {
        setTimeout(() => setWidth(FIXED_PAGE_WIDTH), 200); //this is just to fix page blank issue when first loading any iFrame
      }
      if (hasBeenOpened === false && isLoaded === true) {
        setHasBeenOpened(true);
      }
    }, [isLoaded, hasBeenOpened, isActiveBoard]);

    const boardLink = getBoardLink(
      currentBoard as UserBoardConfig,
      props.auth.user,
    );

    const iframeRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
      iframe: iframeRef.current,
      boardId: getBoardId(currentBoard),
      env: "env" in currentBoard ? currentBoard.env : undefined,
    }));

    const getCurrentBoardOffset = () => {
      if ("offsetVertical" in currentBoard) {
        return currentBoard.offsetVertical;
      }
      return undefined;
    };

    function handleBoardLoaded() {
      setIsLoaded(true);
    }

    return (
      <>
        {!hasBeenOpened && !props.isActiveBoard && null}
        {!isLoaded && (
          <BoardLoader
            onLoaded={handleBoardLoaded}
            key={getBoardId(currentBoard)}
            id={getBoardId(currentBoard)}
            start={!hasBeenOpened && isActiveBoard}
          />
        )}
        {hasBeenOpened && (
          <div
            className={
              `w-${width} h-100 ` +
              (isLoaded && checkCurrentBoard(props) ? "" : "d-none")
            }
            style={{ width: `${width} %!important` }}
          >
            <iframe
              title={currentBoard.name}
              src={boardLink}
              className={`w-${width} border-0`}
              width={`${width}%`}
              height="90%"
              ref={iframeRef}
              style={{ marginTop: getCurrentBoardOffset() }}
            ></iframe>
          </div>
        )}
      </>
    );
  },
);
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  BoardScreen,
);
