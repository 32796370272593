import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserBoardsEditorState } from "./userBoardsEditor.states";

const initialState: UserBoardsEditorState = {
  isOpenPersonalBoardsModal: false,
};

export const userBoardsEditorSlice = createSlice({
  name: "userBoardsEditor",
  initialState: initialState,
  reducers: {
    toggleUserBoardsModal: (state) => {
      state.isOpenPersonalBoardsModal = !state.isOpenPersonalBoardsModal;
    },
    setUserBoardsSearchValue: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.manageUserBoardsSearchValue = action.payload;
    },
    setUserBoardsCopyNameValue: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.manageUserBoardsCopyNameValue = action.payload;
    },
    setUserBoardsCopyUrlValue: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.manageUserBoardsCopyUrlValue = action.payload;
    },
  },
});

export default userBoardsEditorSlice;
