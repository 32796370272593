/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";

const mapStateToProps = (state: RootState) => ({
  data: { ...state.datas },
});

export type Props = ReturnType<typeof mapStateToProps>;

export const ApiMonitoringModal: React.FC<Props> = (props: Props) => {
  function checkApisErrors() {
    const apisWithTooMuchErrors: string[] = [];
    props.data.healthCheckDetails.forEach((item) => {
      if (
        item.totalRequests > 10 &&
        item.nbErrors > 0 &&
        item.nbErrors / item.totalRequests > 0.5
      ) {
        apisWithTooMuchErrors.push(item.serviceName);
      }
    });

    setDowntimedApis(apisWithTooMuchErrors);
  }

  const [downtimedApis, setDowntimedApis] = React.useState<
    string[] | undefined
  >([]);

  React.useEffect(() => {
    if (props.data.healthCheckDetails === undefined) {
      setDowntimedApis(["Cli Global View API"]);
    } else {
      checkApisErrors();
    }
  }, [props.data.healthCheckDetails]);

  return (
    <>
      {(downtimedApis.length > 0 || downtimedApis === undefined) && (
        <ToastContainer className="p-5" position="top-end">
          <Toast>
            <Toast.Body className="alert alert-danger">
              <div className="d-flex justify-content-between pb-3">
                <div className="d-flex">
                  <i className="icon pe-2">warning</i>
                  <h3 className="modal-title">API Service alert</h3>
                </div>

                <i
                  style={{ cursor: "pointer" }}
                  className="icon pe-2"
                  onClick={() => setDowntimedApis([])}
                >
                  close
                </i>
              </div>
              <div className="mb-2">
                The following APIs used by this tool seem to be experiencing
                downtime :
                <br />
              </div>
              <ul>
                {downtimedApis.map((api) => (
                  <li key={api}>
                    <b>{api}</b>
                  </li>
                ))}
              </ul>
              <div className="mt-2 mb-2">
                We are investigating the issue with the corresponding support
                team and working to resolve it as quickly as possible. Thank you
                for your patience.
                <br />
              </div>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
};
export default connect(mapStateToProps)(ApiMonitoringModal);
