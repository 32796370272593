import ENDPOINTS from "common/constants/endpoints.const";
import { createRepository } from "common/utils/api/api";
import { fromJsonToObject } from "common/utils/api/api.helper";
import {
  DefaultBoardConfig,
  DefaultBoardConfigDataset,
} from "datas/models/DefaultBoardConfig.m";
import _ from "lodash";

function getOnlyTeamBoards(
  boards: DefaultBoardConfig[],
  userTeamConfigIds: string[],
): DefaultBoardConfig[] {
  if (!Array.isArray(userTeamConfigIds) || userTeamConfigIds.length === 0) {
    return [];
  }
  return boards
    .filter((board) => board)
    .filter((d) => userTeamConfigIds.some((id) => d?.teamProfileId === id));
}

export function getUserTeamDefaultBoards(
  userTeamConfigIds: string[],
): Promise<DefaultBoardConfig[]> {
  return createRepository()
    .getStreamFromAPI(ENDPOINTS.DefaultBoardConfigs)
    .then((response) => (response.ok ? response.text() : Promise.reject()))
    .then((responseText) => {
      return fromJsonToObject<DefaultBoardConfigDataset>(responseText);
    })
    .then((data) => {
      const arrayData = [] as DefaultBoardConfig[];

      for (const key of Object.keys(data)) {
        const enrichedData = data[key].map((board) => {
          return {
            ...board,
            teamProfileId: key,
          } as DefaultBoardConfig;
        });
        arrayData.push(...enrichedData);
      }
      return arrayData;
    })
    .then((boards) => getOnlyTeamBoards(boards, userTeamConfigIds))
    .then((response) => _.orderBy(response, "position"))
    .catch((error) => {
      console.log(error);
      return [];
    });
}
