/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { SgwtAccountCenter } from "@sgwt/sgwt-widgets-react";
import { getEnvironment } from "app/config/environment";
import { RootState } from "app/store/store.states";
import { ROUTES } from "common/constants/routes.const";
import homeSlice from "modules/Home/store/home.slice";
import { Dispatch } from "redux";

const mapStateToProps = (state: RootState) => ({
  home: { ...state.modules.home },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleAutomateQuickCreateModal: () =>
    dispatch(homeSlice.actions.toggleAutomateQuickCreateModal()),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const Header: React.FC<Props> = (props: Props) => {
  return (
    <nav className="navbar bg-white">
      <div className="navbar-title">
        <NavLink to={ROUTES.HOME} className="navbar-title-link">
          <div className="navbar-logo">
            <img
              src="https://shared.sgmarkets.com/assets/images/socgen_logo_muted.svg"
              height="32"
              alt="SG Markets"
              className="d-block"
            />
          </div>
          <div className="navbar-title-divider"></div>
        </NavLink>
        <button
          className="navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center"
          type="button"
          data-bs-offset="0,4"
          aria-expanded="true"
        >
          <i className="icon">menu</i>
        </button>
      </div>
      <div className="navbar-content">
        <ul className="navbar-navigation m-0">
          <NavLink to={ROUTES.HOME} className="navbar-link">
            Home
          </NavLink>
          <button
            className="navbar-link"
            onClick={(e) => {
              e.preventDefault();
              props.toggleAutomateQuickCreateModal();
            }}
          >
            Automate
          </button>
        </ul>
      </div>
      <SgwtAccountCenter
        id="my-account-center"
        environment={getEnvironment() === "production" ? "" : getEnvironment()}
        mode="sg-markets"
        language="en"
        authentication="sg-connect"
      />
      <div>
        <svg
          className="d-none d-md-block overflow-visible ms-*3"
          width="145"
          height="38"
        >
          <path d="m 0,10.448485 h 1.8787878 c 0.2545455,2.581818 1.830303,3.927272 4.7151515,3.927272 2.1090904,0 3.6242427,-0.957575 3.6242427,-2.860606 0,-2.0848481 -1.8303033,-2.5818178 -4.5696972,-3.1757572 C 2.9090908,7.7454544 0.6545454,6.9696969 0.6545454,4.0969696 c 0,-2.5212121 2.0848485,-4.03636359 5.1878788,-4.03636359 3.8181815,0 5.3939398,1.93939389 5.7818188,4.75151509 H 9.8060607 C 9.5272727,2.630303 8.121212,1.5515151 5.8787878,1.5515151 c -2.2424242,0 -3.430303,0.8848485 -3.430303,2.460606 0,1.5757576 1.7090909,2.2424242 3.9878787,2.6909091 2.9696972,0.5939394 5.6727275,1.3212121 5.6727275,4.7515148 0,2.860606 -2.3272733,4.460606 -5.6121214,4.460606 C 2.2181818,15.915151 0.1696969,13.527273 0,10.448485 Z" />
          <path d="m 25.224242,13.612121 h -0.04848 c -1.115152,1.551515 -2.751516,2.327273 -4.751516,2.327273 -4.290909,0 -7.163636,-3.260606 -7.163636,-7.8909093 0,-4.630303 2.533334,-7.9999999 7.333333,-7.9999999 3.539394,0 5.721213,1.9151515 6.206061,4.9090909 H 24.824242 C 24.4,2.9333333 22.969697,1.7090908 20.557576,1.7090908 c -3.672727,0 -5.333333,2.7515152 -5.333333,6.3393939 0,3.5878783 2.181818,6.2303033 5.272727,6.2303033 3.090909,0 4.60606,-2.133334 4.60606,-4.6909094 V 9.2242423 H 20.509091 V 7.551515 h 6.509091 v 8.060606 h -1.236364 z" />
          <path d="m 39.066667,0.36363631 h 4.351515 l 2.157575,7.66060599 c 0.533334,1.9151515 0.957576,4.1212117 0.957576,4.1212117 h 0.04849 c 0,0 0.4,-2.193939 0.933333,-4.1212117 l 2.157576,-7.66060599 h 4.412121 V 15.612121 H 51.2 V 6.8727272 c 0,-1.2363637 0.133333,-3.1515152 0.133333,-3.1515152 h -0.04848 c 0,0 -0.339394,1.7939394 -0.666666,2.8969697 L 48,15.624242 H 45.030303 L 42.472727,6.6181817 C 42.157576,5.5030302 41.80606,3.721212 41.80606,3.721212 h -0.04848 c 0,0 0.133333,1.9151515 0.133333,3.1515152 v 8.7515148 h -2.824242 z" />
          <path d="m 60.20606,0.36363631 h 3.2 L 68.787878,15.612121 h -3.224242 l -1.018182,-3.248485 h -5.515151 l -1.006061,3.248485 H 54.896969 Z M 59.757575,9.9999999 h 4.036364 L 62.424242,5.6121211 C 62.169697,4.7999999 61.80606,3.260606 61.80606,3.260606 h -0.04848 c 0,0 -0.387878,1.5393939 -0.642424,2.3515151 z" />
          <path d="m 75.321212,9.721212 h -2.509091 v 5.890909 h -3.2 V 0.36363631 h 6.70303 c 1.769697,0 3.090909,0.50909091 3.987879,1.43030299 a 4.5454545,4.5454545 0 0 1 1.369697,3.3090909 c 0,2.1333333 -1.115152,3.6242424 -3.030303,4.2666666 l 3.818182,6.2545452 h -3.6 z M 72.812121,7.2121211 h 3.2 c 1.624242,0 2.472727,-0.7515152 2.472727,-2.0848485 0,-1.3333333 -0.89697,-2.0727272 -2.436364,-2.0727272 h -3.236363 z" />
          <path d="m 83.321212,0.36363631 h 3.090909 V 6.4363635 l 5.866666,-6.0848484 h 3.842424 L 90.157575,6.4606059 96.50909,15.612121 H 92.884848 L 87.975757,8.4848484 86.424242,10.060606 v 5.551515 h -3.10303 z" />
          <path d="M 97.296969,0.36363631 H 108.52121 V 2.9696969 h -8.13333 v 3.4060606 h 7.12727 V 8.921212 h -7.12727 v 4.121212 h 8.19393 v 2.581818 H 97.296969 Z" />
          <path d="m 109.29697,0.36363631 h 12.26666 V 2.9696969 h -4.58182 V 15.612121 h -3.0909 V 2.9696969 h -4.59394 z" />
          <path d="m 121.87878,10.89697 h 3.03031 c 0.21818,1.830303 1.2606,2.557575 3.4303,2.557575 1.57576,0 2.9697,-0.557575 2.9697,-1.963636 0,-1.406061 -1.45455,-1.7939394 -3.78182,-2.3272728 -2.72727,-0.6181818 -5.22424,-1.3454545 -5.22424,-4.5212121 0,-3.1757575 2.44848,-4.56969688 5.84242,-4.56969688 3.39394,0 5.64849,1.68484848 5.89091,4.75151508 h -2.9697 c -0.16969,-1.5393939 -1.36969,-2.3030303 -2.94545,-2.3030303 -1.57576,0 -2.75152,0.7030303 -2.75152,1.7939394 0,1.0909091 1.06667,1.6 3.33334,2.0848485 3.13939,0.6787879 5.69697,1.430303 5.69697,4.7151511 0,3.090909 -2.49697,4.824243 -5.92728,4.824243 -4.14545,0.01212 -6.48485,-1.818182 -6.59394,-5.042424 z" />
          <rect
            fill="#E60028"
            height="16"
            width="1.6969696"
            x="31.430305"
            y="0"
          />
          <text
            className="font-family-display"
            x="0"
            y="32"
            height="16"
            width="145"
          >
            Sales analytics 360
          </text>
        </svg>
      </div>
    </nav>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
