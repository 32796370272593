import { MyAppConfiguration } from "./configuration";
import { Environment } from "./environment";

export const getApiServiceUrl = (): string => MyAppConfiguration.apiUrl;
export const getApiScopes = (): string => MyAppConfiguration.scope;

export const getIcWidgetUrl = (env: Environment): string =>
  env === "production"
    ? "https://shared.sgmarkets.com/ic-widgets/v1/"
    : "https://shared-uat.sgmarkets.com/ic-widgets/v1/";
