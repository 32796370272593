import { configureStore } from "@reduxjs/toolkit";
import datasSlice from "datas/store/datas.slice";
import { authReducer } from "modules/Authentication/store/auth.slice";
import boardSlice from "modules/Board/store/board.reducer";
import appSlice from "./app.slice";
import modulesReducers from "./modules.reducer";
import { rtkQueryErrorLogger } from "./rtkQueryError";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    modules: modulesReducers,
    datas: datasSlice.reducer,
    auth: authReducer,
    boards: boardSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rtkQueryErrorLogger),
});
