export interface UserConfigDTO {
  userId: string;
  alwaysMirror: boolean;
  userBoardsReminderLastDate: string;
  boardsOrder: string[];
  betaFeatures?: BetaFeatures[];
}

export enum BetaFeatures {
  PDF = "pdf",
  SHAREABLE_LINK = "shareablelink",
}
