import * as React from "react";
import { Modal } from "react-bootstrap";

type Props = {
  defaultBoard: string;
};

export const NotFoundChapterModal = (props: Props) => {
  const [open, setOpen] = React.useState<boolean>(true);

  function onClose() {
    setOpen(false);
  }
  return (
    <>
      <Modal centered fade={true} show={open} size="lg" onHide={onClose}>
        <div className="modal-header mb-0 pb-0">
          <h3 className="modal-title">This board was not found</h3>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
          ></button>
        </div>

        <Modal.Body className="pb-6">
          <div className="m-0">
            We cannot find the board you are requesting, you will be redirected
            to {props.defaultBoard || "Home"} board
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
