import { DefaultBoardConfig } from "datas/models/DefaultBoardConfig.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { BoardConfig } from "datas/types/BoardConfig";
import { getSgDashboardMyBoardsBaseURL } from "datas/utils/Boards";
import { endsWith, trim } from "lodash";
import { SG_DASHBOARD_EXPLORE_BASEURL } from "../constants/constant";
import { SGDashboardBoardType } from "./types";

export const isStringEqual = (
  value?: string,
  other?: string,
  trimSpaces = true,
  insensitive = false,
): boolean => {
  if (!value && !other) {
    return true;
  }
  const { value1, value2 } = trimSpaces
    ? { value1: trim(value), value2: trim(other) }
    : { value1: value, value2: other };
  return insensitive
    ? value1 === value2
    : value1.toLowerCase() === value2.toLowerCase();
};

export const isStringEmpty = (value?: string): boolean =>
  value === undefined || value === null || trim(value) === "";

export const pluralize = (word: string, n = 10) => {
  if (endsWith(word, "s") || n <= 1) {
    return word;
  }

  if (endsWith(word, "y")) {
    return `${word.slice(0, -1)}ies`;
  }
  return `${word}s`;
};

const isBoardWithUrl = (boardConfig: BoardConfig): boolean =>
  boardConfig && "boardURL" in boardConfig && !!boardConfig.boardURL;

export const getBoardUrl = (
  type: SGDashboardBoardType,
  boardConfig: BoardConfig,
  fullScreen = false,
): string => {
  if (isBoardWithUrl(boardConfig)) {
    return (boardConfig as DefaultBoardConfig).boardURL;
  }

  let url = "";
  const getFullScreenUrl = () => {
    if (!fullScreen) {
      return "";
    }
    return type === "EXPLORE" ? "/preview?fullscreen=true" : "?fullscreen=true";
  };
  const separator = getFullScreenUrl() ? "&" : "?";
  const configId = boardConfig?.sgBoardConfigId
    ? `${separator}configurationId=${boardConfig?.sgBoardConfigId}`
    : "";
  if (type === "EXPLORE") {
    url =
      boardConfig.sgBoardId != null
        ? SG_DASHBOARD_EXPLORE_BASEURL +
          boardConfig.sgBoardId +
          getFullScreenUrl() +
          configId
        : "";
  } else if (type === "MYBOARD") {
    url = getSgDashboardMyBoardsBaseURL(
      boardConfig.sgBoardId,
      configId,
      getFullScreenUrl(),
      (boardConfig as UserBoardConfig).env,
    );
  }
  return url;
};

export const getBoardId = (boardConfig: BoardConfig): string => {
  if (isBoardWithUrl(boardConfig)) {
    return (boardConfig as DefaultBoardConfig).boardURL;
  }

  return (
    boardConfig?.sgBoardId +
    (boardConfig?.sgBoardConfigId ? "/" + boardConfig?.sgBoardConfigId : "")
  );
};
