import React from "react";
import { createRoot } from "react-dom/client";
import { SgwtConnectHTMLElement } from "@sgwt/sgwt-widgets-react";
import { App } from "./app/components/App";

import "react-toastify/dist/ReactToastify.css";
import "app/styles/main.scss";

declare global {
  interface ImportMeta {
    env: Record<string, string>;
  }
}

// ? Auto-login with SG Connect, using the <sgwt-connect> widget.
const widget = document.querySelector<SgwtConnectHTMLElement>("sgwt-connect");
if (widget === null) {
  document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        No widget found to handle the authentication on this application!
      </div>`;
} else if (widget.sgwtConnect) {
  // ? Widget is already initialized, so can make the SG Connect login...
  login();
} else {
  // ? Widget is not yet initialized, we need to wait for the "ready" event.
  widget.addEventListener("sgwt-connect--ready", login);
}

function login() {
  if (!widget) {
    document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        No widget found to handle the authentication on this application!
      </div>`;
    return;
  }
  const sgwtConnect = widget.sgwtConnect;
  widget.removeEventListener("sgwt-connect--ready", login);
  if (sgwtConnect.getAuthorizationError()) {
    // ? Case #1: an error occurred during the retrieval of the SG Connect token...
    document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        Authorization error: ${sgwtConnect.getAuthorizationError()}.
      </div>`;
  } else if (sgwtConnect.isAuthorized()) {
    // ? Case #2: the user is authenticated, we load the application
    // ! We delay the loading of the application by 0.5s in order to display the SGM Service Loader.
    // ! The component is defined in the main index.html, within the <div id="root"> element.
    // ! Remove that timeout if you do not need this Service Loader component.
    const rootElement = document.querySelector("#root");
    if (rootElement) {
      setTimeout(() => {
        const root = createRoot(rootElement);
        root.render(
          <React.StrictMode>
            <App />
          </React.StrictMode>,
        );
      }, 500);
    }
  } else {
    // ? Case #3: the user is not authenticated, we request an authorization...
    sgwtConnect.requestAuthorization();
  }
}
