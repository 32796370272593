import { MyAppConfiguration } from "app/config/configuration";
import { createRepository } from "common/utils/api/api";
import { fromJsonToObject } from "common/utils/api/api.helper";
import { RequiredActionsDto } from "datas/dtos/RequiredActions.dto";
import { User } from "datas/models/User.m";

export const REQUIRED_ACTION_TYPES = [
  "kycCasesPendingOutreach",
  "icPendingTasks",
  "icPipelineExpired",
  "icPipelineWithNoRecentUpdate",
  "kycOnBoardingCasesToApprove",
  "kycForToCertify",
];

export function getRequiredActions(user: User): Promise<RequiredActionsDto> {
  console.log("Getting number of required actions from Maxx");
  return createRepository()
    .postToExternalApi(
      `${MyAppConfiguration.maxxUrl}getUserRequiredActions`,
      undefined,
      {
        contactId: user.id,
        contactEmail: user.email,
        contactDepartment: user.team,
        actionTypes: REQUIRED_ACTION_TYPES,
      },
    )
    .then((response) => (response?.ok ? response.text() : Promise.reject()))
    .then((responseText) => {
      return fromJsonToObject<RequiredActionsDto>(responseText);
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
}
