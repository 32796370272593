import { getSgConnectToken } from "../../sgConnect";

const SG_CONNECT_TOKEN_MISSING_MESSAGE = "SG-Connect token is missing";

const postRequest = (
  url: string,
  data: string,
  token: string,
): Promise<XMLHttpRequest> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener("abort", () => reject(xhr));
    xhr.addEventListener("error", () => reject(xhr));
    xhr.addEventListener("timeout", () => reject(xhr));
    xhr.addEventListener("load", () => {
      resolve(xhr);
    });
    xhr.onreadystatechange = function () {
      if (
        xhr.readyState === XMLHttpRequest.DONE &&
        !(xhr.status >= 200 && xhr.status < 300)
      ) {
        reject(xhr);
      }
    };
    xhr.open("POST", url);
    xhr.setRequestHeader("Authorization", token);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(data);
  });

const post = async (url: string, data: string): Promise<XMLHttpRequest> => {
  const token = await getSgConnectToken();
  return token
    ? postRequest(url, data, token)
    : Promise.reject(SG_CONNECT_TOKEN_MISSING_MESSAGE);
};

export default post;
