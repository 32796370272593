import * as React from "react";
import { connect } from "react-redux";
import { getApiScopes } from "app/config/getApiServiceUrl";
import { RootState } from "app/store/store.states";
import { Loading } from "common/components/Loading/Loading";
import { logger } from "common/utils/logger";
import { Level } from "common/utils/logger/core/logger.type";
import UserInfoDTO from "datas/dtos/UserInfo.dto";
import {
  getSgwtAccountCenterElement,
  getSgwtConnectElement,
  getWidgetBus,
} from "modules/Authentication/utils/getWidgetBus";
import { Dispatch } from "redux";
import { authActions } from "../store/auth.slice";

const mapStateToProps = (state: RootState) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setToken: (token: string) => dispatch(authActions.setToken(token)),
  logIn: (userEmail: string) =>
    dispatch(authActions.logIn({ email: userEmail })),
  logOut: () => dispatch(authActions.logOut()),
  setUserInfo: (userInfo?: UserInfoDTO) =>
    dispatch(authActions.setUserInfo(userInfo)),
});

export type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  React.PropsWithChildren;

class AuthProvider extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const bus = getWidgetBus();
    if (bus) {
      bus.subscribe("sg-connect.access-token", this.handleTokenChanged);
      bus.publish("sg-connect.granted-scope", getApiScopes());
      bus.subscribe(
        "sg-connect.user-connection",
        this.handleUserConnectionChanged,
      );
      bus.subscribe("sg-connect.user-info", this.handleUserInfoChanged);
    }

    const sgwtConnect = getSgwtConnectElement();
    if (sgwtConnect) {
      sgwtConnect.sgwtConnect.on("authorizationExpired", () =>
        this.handleTokenExpired(),
      );
      sgwtConnect.sgwtConnect.on("renewAuthorizationError", (error) =>
        this.handleOnRenewError(error),
      );
    }
  }

  render() {
    if (!this.props.loggedIn) {
      return <Loading />;
    }

    return this.props.children;
  }

  handleTokenExpired = () => {
    logger().sendTechnicalLog(
      "User redirected to endSession page after sgwtConnect <authorizationExpired> event is fired",
      Level.Warn,
    );
    this.props.logOut();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sgwtAccountCenter = getSgwtAccountCenterElement() as any;
    sgwtAccountCenter.signOut();
  };

  handleOnRenewError = (error) => {
    logger().sendTechnicalLog(
      `sgwtConnect error while trying to renew session token : ${error}`,
      Level.Warn,
    );
  };

  handleTokenChanged = (token?: string) => {
    if (token) {
      this.props.setToken(token);
    }
  };

  handleUserConnectionChanged = (userConnection: {
    mail?: string;
    connected?: boolean;
  }) => {
    if (userConnection?.connected) {
      this.props.logIn(userConnection.mail);
    }
  };

  handleUserInfoChanged = (userInfo: UserInfoDTO | undefined) => {
    this.props.setUserInfo(userInfo);
  };
}

export default connect(
  (state: RootState) => ({ ...state.auth }),
  mapDispatchToProps,
)(AuthProvider);
