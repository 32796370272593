/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "app/store/store.states";
import { formatAndSendCustomLogWithBoardInfo } from "common/utils/logger/helpers";
import { getBoardId } from "common/utils/stringUtils";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { getLinkToBoard } from "modules/Board/components/helpers";
import { Dispatch } from "redux";
import homeSlice from "../store/home.slice";

type UserBoardTabProps = {
  userBoard: UserBoardConfig;
  onSelectBoard: (board: UserBoardConfig) => void;
};

const mapStateToProps = (state: RootState) => ({
  app: { ...state.app },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCloneModal: (userBoard: UserBoardConfig) =>
    dispatch(homeSlice.actions.toggleCloneModal(userBoard)),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  UserBoardTabProps;

export const UserBoardTab: React.FC<Props> = (props: Props) => {
  const [displayIcons, setDisplayIcons] = React.useState(false);

  const onPersonalBoardClicked = () => {
    formatAndSendCustomLogWithBoardInfo(
      props.userBoard,
      "Chapter.PersonalBoard",
      `Click on chapter ${props.userBoard.name} personal board`,
    );
  };

  const onCloneBoardButtonClicked = () => {
    formatAndSendCustomLogWithBoardInfo(
      props.userBoard,
      `Clone.${props.userBoard?.name}`,
      "Clone Board button clicked",
    );
    props.toggleCloneModal(props.userBoard);
  };

  return (
    <div
      role="button"
      onClick={onPersonalBoardClicked}
      tabIndex={0}
      onMouseOver={() => {
        setDisplayIcons(true);
      }}
      onMouseOut={() => {
        setDisplayIcons(false);
      }}
    >
      <NavLink
        className={({ isActive }) =>
          `nav-link p-8px ps-16px d-flex flex-row justify-content-between ${isActive ? "active" : undefined}`
        }
        style={{ textOverflow: "ellipsis" }}
        data-tooltip-id={"tooltip" + getBoardId(props.userBoard)}
        data-tooltip-content="Personal board"
        data-tooltip-place="left"
        data-toggle="tooltip"
        data-placement="right"
        title="Personal board"
        data-original-title="Personal board"
        to={getLinkToBoard(props.userBoard)}
        end
      >
        <div>
          <i className="icon icon-sm pe-2">
            {props.userBoard.icon || "dashboard_customize"}
          </i>
          {props.userBoard.tabName !== undefined &&
          props.userBoard.tabName !== ""
            ? props.userBoard.tabName
            : props.userBoard.name}
        </div>
        <div className="d-flex align-items-center">
          <i
            style={{ cursor: "grab" }}
            className={`icon icon-sm ${displayIcons ? "" : "d-none"}`}
          >
            drag_indicator
          </i>
          <i
            className={`icon icon-sm ${displayIcons ? "" : "d-none"}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onCloneBoardButtonClicked();
            }}
            title="Clone this board"
          >
            content_copy
          </i>
        </div>
      </NavLink>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(UserBoardTab);
