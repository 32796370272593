import { createRepository } from "common/utils/api/api";
import { SgBoardsV2DTO } from "datas/dtos/SgBoardsV2.dto";
import { SgBoardsV3DTO } from "datas/dtos/SgBoardsV3.dto";

const getDomain = (env: string) => {
  return env === "prd"
    ? "dashboard.sgmarkets.com"
    : "api-sg-dashboard-uat.fr.world.socgen";
};

export const getAllOwnerSgBoards = (env = "prd"): Promise<SgBoardsV3DTO> => {
  return createRepository().getFromSG<SgBoardsV3DTO>(
    `https://${getDomain(env)}/api/v2/boards`,
    {
      owned: true,
    },
  );
};

export const getSgBoardById = (
  boardId: string,
  env = "prd",
): Promise<SgBoardsV2DTO[]> => {
  return createRepository().getFromSGWithoutToastify<SgBoardsV2DTO[]>(
    `https://${getDomain(env)}/api/v2/boards`,
    {
      ids: [boardId],
      owned: false,
    },
  );
};

export default {
  getAllOwnerSgBoards,
  getSgBoardById,
};
