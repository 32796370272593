import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { logger } from "common/utils/logger";
import {
  toastifyErrorMessage,
  toastifySuccess,
} from "common/utils/toasterNotifyer";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { getSgBoardById } from "datas/services/SgBoards";
import { BoardConfig } from "datas/types/BoardConfig";
import {
  getBoardConfigFromSharedUrl,
  getUserBoardFromSGBoard,
} from "datas/utils/Boards";
import { isSameBoardIdAndConfigId } from "modules/Board/components/helpers";
import { Dispatch } from "redux";
import userBoardsEditorSlice from "../store/userBoardsEditor.slice";

const mapStateToProps = (state: RootState) => ({
  editor: { ...state.modules.userBoardsEditor },
  auth: { ...state.auth },
  datas: { ...state.datas },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeCopyName: (name: string) =>
    dispatch(userBoardsEditorSlice.actions.setUserBoardsCopyNameValue(name)),
  onChangeCopyUrl: (url: string) =>
    dispatch(userBoardsEditorSlice.actions.setUserBoardsCopyUrlValue(url)),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    addSharedBoard: (userBoard: UserBoardConfig) => void;
  };

const UserBoardsModalSharedLinkForm: React.FC<Props> = (props: Props) => {
  function alreadyExists(boardId: string, configId: string) {
    const allBoards = [
      ...props.datas.defaultBoardConfigs,
      ...props.datas.userBoardConfigs,
    ];
    return allBoards.find((board) =>
      isSameBoardIdAndConfigId(board, boardId, configId),
    );
  }

  const addUserBoardFromLink = () => {
    if (props.editor.manageUserBoardsCopyUrlValue) {
      logger()
        .setCustom("boardName", props.editor.manageUserBoardsCopyNameValue)
        .setCustom("boardLink", props.editor.manageUserBoardsCopyUrlValue)
        .setCustom("action", "Customize.AddShareableLink")
        .sendFunctionalLog("Add personal board from shareable link");

      const boardConfig: BoardConfig = getBoardConfigFromSharedUrl(
        props.editor.manageUserBoardsCopyUrlValue ?? "",
      );

      if (
        boardConfig.sgBoardId &&
        boardConfig.sgBoardConfigId &&
        !alreadyExists(boardConfig.sgBoardId, boardConfig.sgBoardConfigId)
      ) {
        getSgBoardById(boardConfig.sgBoardId, boardConfig.env)
          .then((response) => {
            console.log(response);
            const sgBoard = response.length > 0 ? response[0] : undefined;
            if (sgBoard) {
              props.addSharedBoard(
                getUserBoardFromSGBoard(
                  props.editor.manageUserBoardsCopyNameValue || sgBoard.name,
                  sgBoard.id.toString(),
                  boardConfig.sgBoardConfigId,
                  props.auth.user.email,
                  props.auth.user.id,
                  "",
                  props.editor.manageUserBoardsCopyNameValue || sgBoard.name,
                  boardConfig.env,
                ),
              );
            }
          })
          .then(() =>
            toastifySuccess("Board successfully added from shareable link."),
          )
          .catch((error) => console.log(error));
      } else {
        toastifyErrorMessage(
          "Add from link failed. \nThis board may already exists, or please check your shareable link.",
        );
      }
    }
  };

  return (
    <div className="row form-row">
      <div className="col col-sm-7">
        <div className="form-group m-0">
          <input
            type="input"
            className="form-control form-control-lg"
            placeholder={"Shareable link"}
            value={props.editor.manageUserBoardsCopyUrlValue ?? ""}
            onChange={(e) => props.onChangeCopyUrl(e.target.value)}
          />
        </div>
      </div>

      <div className="col col-sm-4">
        <div className="form-group m-0">
          <input
            type="input"
            className="form-control form-control-lg"
            placeholder={"Board name (optional)"}
            value={props.editor.manageUserBoardsCopyNameValue ?? ""}
            onChange={(e) => props.onChangeCopyName(e.target.value)}
          />
        </div>
      </div>

      <div className="col col-sm-1">
        <button
          type="button"
          className="btn btn-lg btn-default "
          data-dismiss="modal"
          data-testid="Submit"
          onClick={addUserBoardFromLink}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserBoardsModalSharedLinkForm);
