/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "app/store/store.states";
import { getDateString } from "common/utils/dateUtils";
import { logger } from "common/utils/logger";
import { BetaFeatures } from "datas/dtos/UserConfig.dto";
import { UserConfig } from "datas/models/User.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import {
  getUserBoardsFromDB,
  putUserBoards,
} from "datas/services/userBoardConfigs";
import { updateUserConfig } from "datas/services/userConfig.service";
import datasSlice from "datas/store/datas.slice";
import { getUserBoardFromSGBoard } from "datas/utils/Boards";
import { authActions } from "modules/Authentication/store/auth.slice";
import { Dispatch } from "redux";
import userBoardsEditorSlice from "../store/userBoardsEditor.slice";
import AlwaysMirrorCheckbox from "./AlwaysMirrorCheckbox";
import UserBoardsList from "./UserBoardsList";
import UserBoardsModalSearch from "./UserBoardsModalSearch";
import UserBoardsModalSharedLinkForm from "./UserBoardsModalSharedLinkForm";

type ModalButton = "SAVE" | "CANCEL" | "CLOSE";

const mapStateToProps = (state: RootState) => ({
  datas: { ...state.datas },
  editor: { ...state.modules.userBoardsEditor },
  auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUserBoards: (boards: UserBoardConfig[]) =>
    dispatch(
      datasSlice.actions.updateAll({ type: "userBoardConfigs", datas: boards }),
    ),
  toggleUserBoardsModal: () =>
    dispatch(userBoardsEditorSlice.actions.toggleUserBoardsModal()),
  onChangeCopyName: (name: string) =>
    dispatch(userBoardsEditorSlice.actions.setUserBoardsCopyNameValue(name)),
  onChangeCopyUrl: (url: string) =>
    dispatch(userBoardsEditorSlice.actions.setUserBoardsCopyUrlValue(url)),
  setUserOptionsConfig: (options: UserConfig) =>
    dispatch(authActions.setOptionsConfig(options)),
});
type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const UserBoardsEditor: React.FC<Props> = (props: Props) => {
  const [selectedUserBoardsState, setSelectedUserBoardsState] = React.useState(
    props.datas.userBoardConfigs,
  );
  const [alwaysMirrorState, setAlwaysMirrorState] = React.useState(
    props.auth.user.optionsConfig?.alwaysMirror,
  );

  React.useEffect(() => {
    setSelectedUserBoardsState(props.datas.userBoardConfigs);
    props.onChangeCopyName("");
    props.onChangeCopyUrl("");
    setAlwaysMirrorState(props.auth.user.optionsConfig?.alwaysMirror);
  }, [props.editor.isOpenPersonalBoardsModal]);

  function onAddLinkUserBoardConfig(userBoardConfig: UserBoardConfig) {
    setSelectedUserBoardsState([...selectedUserBoardsState, userBoardConfig]);

    putUserBoards(props.auth.user.id, [
      ...selectedUserBoardsState,
      userBoardConfig,
    ]).then(() =>
      getUserBoardsFromDB(props.auth.user).then((d) => props.setUserBoards(d)),
    );
  }

  function onUpdateUserBoards() {
    putUserBoards(props.auth.user.id, selectedUserBoardsState)
      .then(() => props.toggleUserBoardsModal())
      .then(() =>
        getUserBoardsFromDB(props.auth.user).then((d) =>
          props.setUserBoards(d),
        ),
      );
  }

  function onUpdateUserConfig(newConfig: UserConfig) {
    updateUserConfig(props.auth.user.id, newConfig).then(() =>
      props.setUserOptionsConfig(newConfig),
    );
  }

  function onSaveButtonClicked() {
    onUpdateUserBoards();

    if (alwaysMirrorState !== props.auth.user.optionsConfig?.alwaysMirror) {
      const newUserConfig: UserConfig = {
        ...props.auth.user.optionsConfig,
        alwaysMirror: alwaysMirrorState,
        userBoardsReminderLastDate: !alwaysMirrorState
          ? getDateString(new Date())
          : props.auth.user.optionsConfig.userBoardsReminderLastDate,
      };
      onUpdateUserConfig(newUserConfig);
    }
  }

  const onModalButtonClicked = (buttonType: ModalButton) => {
    switch (buttonType) {
      case "SAVE": {
        const keys = Array.from(selectedUserBoardsState.keys());
        logger()
          .setCustom("boardsId", keys.join(","))
          .setCustom("action", "Customize.AddPersonalBoard.Save")
          .sendFunctionalLog("Save personal boards");
        onSaveButtonClicked();
        break;
      }
      case "CANCEL":
        logger()
          .setCustom("action", "Customize.Cancel")
          .sendFunctionalLog("Manage personal board modal canceled");
        props.toggleUserBoardsModal();
        break;
      case "CLOSE":
        logger()
          .setCustom("action", "Customize.Close")
          .sendFunctionalLog("Manage personal board modal closed");
        props.toggleUserBoardsModal();
        break;
    }
  };

  function handleSelectedBoardsChanged(selectedUserBoards: UserBoardConfig[]) {
    setSelectedUserBoardsState(selectedUserBoards);
  }

  function handleAlwaysMirrorCheckboxChange() {
    if (!alwaysMirrorState) {
      const allUserBoards = props.datas.allUserSgBoards.content.map((sgBoard) =>
        getUserBoardFromSGBoard(
          sgBoard.name,
          sgBoard.id.toString(),
          "",
          props.auth.user.email,
          props.auth.user.id,
        ),
      );

      setSelectedUserBoardsState(allUserBoards);
    }

    setAlwaysMirrorState(!alwaysMirrorState);
  }

  return (
    <>
      <Modal
        centered
        show={props.editor.isOpenPersonalBoardsModal}
        size="xl"
        onHide={props.toggleUserBoardsModal}
        dialogClassName="modal-90w"
      >
        <div className="modal-header mb-0 pb-0">
          <h3 className="modal-title">Customize your personal boards</h3>
          <button
            onClick={() => onModalButtonClicked("CLOSE")}
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        {props.datas.allUserSgBoards &&
        props.datas.allUserSgBoards.content.length === 0 &&
        !props.editor.manageUserBoardsSearchValue ? (
          <Modal.Body className="pb-0 mt-5 d-flex justify-content-center text-center text-warning">
            <p className="modal-title">
              You have not created any personal board yet. Go to SGM Dashboard
              to start creating boards, then they will be proposed here.
            </p>
          </Modal.Body>
        ) : (
          <>
            <Modal.Body className="pb-2">
              <div className="mb-2">
                You can personalize the list of boards in the left-side menu. To
                do so, you have to follow these steps :
              </div>

              <div className="d-flex flex-column justify-content-start align-items-center">
                <img
                  src="https://client360.sgmarkets.world.socgen/assets/img/boards.png"
                  width="40%"
                  alt=""
                />
              </div>

              <br />
              {props.auth.user.optionsConfig?.betaFeatures &&
                props.auth.user.optionsConfig?.betaFeatures.includes(
                  BetaFeatures.SHAREABLE_LINK,
                ) && (
                  <>
                    <div className="modal-header mb-3 pb-0 pt-4">
                      <p className="modal-title justify-content-start">
                        Copy/paste board <b>shareable link</b> from SGM
                        Dashboard.
                      </p>
                    </div>

                    <div className="me-4 ms-4 pb-2 mt-0">
                      <UserBoardsModalSharedLinkForm
                        addSharedBoard={onAddLinkUserBoardConfig}
                      />
                    </div>

                    <div className="me-4 ms-4 d-flex align-items-center">
                      <hr className="my-3 flex-grow-1" />
                      <div className="py-3 text-secondary">&nbsp;OR&nbsp;</div>
                      <hr className="my-3 flex-grow-1" />
                    </div>
                  </>
                )}

              <div className="modal-header mb-3 pb-0 pt-2">
                <p className="modal-title justify-content-start">
                  Select boards from the list of <b>your personal boards</b>{" "}
                  created in SGM Dashboard, and click <b>Save</b> :
                </p>
              </div>

              <div className="me-4 ms-4 mb-4 mt-0">
                <UserBoardsModalSearch />
              </div>

              <div className="me-4 ms-4 mb-2 mt-2">
                <AlwaysMirrorCheckbox
                  isAlwaysMirrorActive={alwaysMirrorState}
                  onAlwaysMirrorChange={handleAlwaysMirrorCheckboxChange}
                />
              </div>

              <div className="me-4 ms-4 mb-4 mt-0">
                <UserBoardsList
                  allUserSgBoards={props.datas.allUserSgBoards}
                  allUserBoards={props.datas.userBoardConfigs}
                  onSelectedBoardsChanged={handleSelectedBoardsChanged}
                  searchedTitle={props.editor.manageUserBoardsSearchValue}
                  isDisabled={alwaysMirrorState}
                  allSelected={alwaysMirrorState}
                />
              </div>
            </Modal.Body>
          </>
        )}

        <Modal.Footer className="pb-2 pt-1">
          {props.datas.allUserSgBoards &&
          props.datas.allUserSgBoards.content.length === 0 &&
          !props.editor.manageUserBoardsSearchValue ? (
            <>
              <button
                type="button"
                className="btn btn-lg btn-flat-secondary"
                data-dismiss="modal"
                onClick={() => onModalButtonClicked("CLOSE")}
              >
                Close
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-lg btn-flat-secondary"
                data-dismiss="modal"
                onClick={() => onModalButtonClicked("CANCEL")}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-lg btn-primary "
                data-dismiss="modal"
                data-testid="Submit"
                onClick={() => onModalButtonClicked("SAVE")}
              >
                Save
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(UserBoardsEditor);
