import { getEnvironment } from "app/config/environment";
import { isStringEmpty } from "../stringUtils";
import Logger from "./core";
import { mapWidgetEnvToLoggerEnv } from "./utils";

const DEFAULT_COMPONENT_NAME = "sales360-app";

type LogData = {
  action: string;
  feature: string;
};

export const logger = (component?: string): Logger => {
  const env = getEnvironment();
  const logger = new Logger(
    "Sales 360",
    mapWidgetEnvToLoggerEnv(env),
    isStringEmpty(component) ? DEFAULT_COMPONENT_NAME : component,
  );
  logger
    .setCustom("type", "FeatureData")
    .setCustom("timestamp", new Date().toLocaleString())
    .setCustom("requestUri", window.location.href)
    .setCustom("url", window.location.href)
    .setCustom("environment", env)
    .setCustom("description", "Sales Analytics 360")
    .setCustom("feature", "CPR-SALESANALYTICS-UI");

  return logger;
};

export const logData = (action: string): string => {
  const logData: LogData = {
    action: action,
    feature: DEFAULT_COMPONENT_NAME,
  };
  return JSON.stringify(logData);
};
