import { combineSlices } from "@reduxjs/toolkit";
import { boardSlice } from "modules/Board/store/board.reducer";
import homeSlice from "modules/Home/store/home.slice";
import userBoardsEditorSlice from "modules/UserBoardsEditor/store/userBoardsEditor.slice";

export const modulesReducers = combineSlices({
  board: boardSlice.reducer,
  home: homeSlice.reducer,
  userBoardsEditor: userBoardsEditorSlice.reducer,
});

export default modulesReducers;
