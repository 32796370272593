/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import {
  PDF_CONVERT_EVENT_TYPE,
  PDF_HELLO_EVENT_TYPE,
  PDF_PING_EVENT_TYPE,
  PDF_PONG_EVENT_TYPE,
} from "@client360-pdf/converter";
import { Coordinates } from "@client360-pdf/converter/dist/common/constants";
import { RootState } from "app/store/store.states";
import Logo from "common/components/Logo/Logo";
import { isJson } from "common/utils/stringify";
import { getBoardId } from "common/utils/stringUtils";
import {
  toastifyErrorMessage,
  toastifySuccess,
} from "common/utils/toasterNotifyer";
import { isIterable } from "common/utils/types";
import useTimer from "common/utils/useTimer";
import { BetaFeatures } from "datas/dtos/UserConfig.dto";
import boardSlice from "modules/Board/store/board.reducer";
import pdfMake from "pdfmake/build/pdfmake";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { Dispatch } from "redux";
import homeSlice from "../store/home.slice";

const pdfMakeFonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

pdfMake.fonts = pdfMakeFonts;

const mapStateToProps = (state: RootState) => ({
  app: { ...state.app },
  auth: { ...state.auth },
  boards: { ...state.boards.boards },
  home: { ...state.modules.home },
});
const lineBreak = { text: "   ", margin: [0, 30, 0, 0] } as Content;
const WIDGET_DOWNLOAD_DELAY_IN_SECONDS = 5;
const WIDGET_PINGPONG_DELAY_IN_SECONDS = 1;
const mapDispatchToProps = (dispatch: Dispatch) => ({
  enableExportPdf: () => dispatch(homeSlice.actions.enableExportPdf()),
  disableExportPdf: () => dispatch(homeSlice.actions.disableExportPdf()),
  addWidget: (boardId: string, widgetName: string) =>
    dispatch(boardSlice.actions.addWidget({ boardId, widgetName })),
  resetBoard: (boardId: string) =>
    dispatch(boardSlice.actions.resetBoard({ boardId })),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onPostMessage: (event_type: string, id?: number) => void;
  };

export const HomeHeader: React.FC<Props> = (props: Props) => {
  const [pingPongId, setPingPongId] = useState(1);
  const {
    isTimedOut: downloadTimerTimedOut,
    startTimer: startDownloadTimer,
    resetTimer: resetDownloadTimer,
  } = useTimer(WIDGET_DOWNLOAD_DELAY_IN_SECONDS);

  const {
    isTimedOut: pingPongTimerTimedOut,
    startTimer: startPingPongTimer,
    resetTimer: resetPingPongTimer,
  } = useTimer(WIDGET_PINGPONG_DELAY_IN_SECONDS);

  const nbWidgetResponses = React.useRef(0);
  const pdfs = React.useRef<{ content: Content[]; coordinates: Coordinates }[]>(
    [],
  );
  const nbWidgetsInBoard =
    props.boards[getBoardId(props.app.currentBoard)]?.length || 0;

  const resetExportPdf = React.useCallback(() => {
    props.enableExportPdf();
    pdfs.current = [];
    nbWidgetResponses.current = 0;
    console.debug("PDF - Reset nb widgets reponse to 0");
  }, [props.enableExportPdf]);

  const onPostMessage = React.useCallback(() => {
    resetExportPdf();
    console.debug(
      `PDF - Reset nb widgets counter for board ${getBoardId(props.app.currentBoard)} to 0`,
    );
    props.resetBoard(getBoardId(props.app.currentBoard));
    resetPingPongTimer();
    startPingPongTimer();
    setPingPongId((id) => id + 1);
  }, [props.onPostMessage]);

  React.useEffect(() => {
    console.debug(`PDF - Start ping pong timer with id =${pingPongId}`);
    props.onPostMessage(PDF_PING_EVENT_TYPE, pingPongId);
  }, [pingPongId]);

  React.useEffect(() => {
    if (downloadTimerTimedOut) {
      toastifyErrorMessage("Export PDF timed out");
      resetDownloadTimer();
      resetExportPdf();
    }
  }, [downloadTimerTimedOut]);

  React.useEffect(() => {
    if (pingPongTimerTimedOut) {
      console.debug("PDF - Ping pong timer timed out");
      resetDownloadTimer();
      startDownloadTimer();
      console.debug("PDF - Start PDF reception timer");
      props.onPostMessage(PDF_CONVERT_EVENT_TYPE);
    }
  }, [pingPongTimerTimedOut]);

  const manageConvertMessage = (data) => {
    nbWidgetResponses.current++;
    console.debug(
      `PDF - New response PDF. Response ${nbWidgetResponses.current}/${nbWidgetsInBoard}`,
    );
    if (data.content) {
      const widgetContent: Content[] = JSON.parse(data.content);
      const coordinates: Coordinates = data.coordinates;
      console.log("PDF message coordinates", coordinates);
      const iterableContent = isIterable(widgetContent)
        ? widgetContent
        : [widgetContent];
      const pdf = { content: [...iterableContent, lineBreak], coordinates };
      pdfs.current = [...pdfs.current, pdf].sort((a, b) =>
        (a.coordinates?.top ?? 0) === (b.coordinates?.top ?? 0)
          ? (a.coordinates?.left ?? 0) - (b.coordinates?.left ?? 0)
          : (a.coordinates?.top ?? 0) - (b.coordinates?.top ?? 0),
      );
      console.log("Pdfs sorted", pdfs.current);
    }
    if (nbWidgetsInBoard <= nbWidgetResponses.current) {
      console.debug(`PDF - All responses received`);
      resetDownloadTimer();
      console.debug(`PDF - Download timer. PDF creation starting`);
      const VERY_LARGE_PAGE_WIDTH = 4000;
      const VERY_LARGE_PAGE_HEIGHT = 4000;
      const getFullContent = () => {
        return [...pdfs.current.flatMap((pdf) => pdf.content)];
      };

      const docDefinition: TDocumentDefinitions = {
        content: getFullContent(),
        pageOrientation: "landscape",
        pageSize: {
          width: VERY_LARGE_PAGE_WIDTH,
          height: VERY_LARGE_PAGE_HEIGHT,
        },
      };
      const doc = pdfMake.createPdf(docDefinition);
      doc.download();
      toastifySuccess("Export PDF success");
      resetExportPdf();
    }
  };

  const handleMessage = React.useCallback(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (event: any) => {
      if (event.data && typeof event.data === "string" && isJson(event.data)) {
        console.debug(`PDF receive message`, event.data);
        const data = JSON.parse(event.data);
        if (
          (data.type === PDF_PONG_EVENT_TYPE && data.id === pingPongId) ||
          data.type === PDF_HELLO_EVENT_TYPE
        ) {
          console.debug(`PDF Receive ${data.type} message`, data);
          props.addWidget(getBoardId(props.app.currentBoard), data.widgetName);
          props.enableExportPdf();
        } else if (data.type === PDF_PONG_EVENT_TYPE) {
          console.warn(
            `PDF Received PONG reply with wrong id: ${data?.id}`,
            data,
          );
        } else if (data.type === PDF_CONVERT_EVENT_TYPE) {
          console.info(`PDF Received PDF doc message`, data);
          manageConvertMessage(data);
        }
      }
    },
    [
      props.app.currentBoard,
      PDF_PONG_EVENT_TYPE,
      PDF_CONVERT_EVENT_TYPE,
      nbWidgetsInBoard,
      downloadTimerTimedOut,
      startDownloadTimer,
      resetDownloadTimer,
      resetExportPdf,
    ],
  );

  React.useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  React.useEffect(() => {
    if (nbWidgetsInBoard === 0) {
      props.disableExportPdf();
    } else {
      props.enableExportPdf();
    }
    resetDownloadTimer();
  }, [props.app.currentBoard]);

  return (
    <>
      <div className="d-flex mb-3 ms-4 me-2 justify-content-between">
        <Logo name={"Sales analytics 360"} />
        {props.app.currentBoard &&
          props.auth.user.optionsConfig?.betaFeatures &&
          props.auth.user.optionsConfig?.betaFeatures.includes(
            BetaFeatures.PDF,
          ) && (
            <Button
              onClick={onPostMessage}
              text=""
              className="me-2"
              disabled={props.home.exportPdfDisabled}
            >
              <i style={{ cursor: "grab" }} className={"icon icon-sm"}>
                picture_as_pdf
              </i>
              &nbsp;Export pdf
            </Button>
          )}
      </div>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
