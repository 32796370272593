import { createSlice } from "@reduxjs/toolkit";
import { BoardsState } from "./board.states";

const initialState: BoardsState = {
  boards: {},
};

export const boardSlice = createSlice({
  name: "boards",
  initialState: initialState,
  reducers: {
    addWidget: (state, action) => {
      const widgetName = action.payload.widgetName;
      const boardId = action.payload.boardId;
      if (state.boards[boardId]) {
        state.boards[boardId] = [widgetName, ...state.boards[boardId]];
      } else {
        state.boards[boardId] = [widgetName];
      }
      console.log(widgetName, boardId, state.boards[boardId]);
    },
    resetBoard: (state, action) => {
      if (state.boards[action.payload.boardId]) {
        state.boards[action.payload.boardId] = [];
      }
    },
  },
});

export default boardSlice;
