/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { connect } from "react-redux";
import appSlice from "app/store/app.slice";
import { RootState } from "app/store/store.states";
import {
  DAYS_TO_REMINDER,
  SG_DASHBOARD_ORIGIN,
  SG_DASHBOARD_UAT_ORIGIN,
} from "common/constants/constant";
import { addDays, getDateString } from "common/utils/dateUtils";
import { CgvHealthDetailsDto } from "datas/dtos/CgvHealthDetails.dto";
import { SgBoardsV3DTO } from "datas/dtos/SgBoardsV3.dto";
import { UserConfigDTO } from "datas/dtos/UserConfig.dto";
import { DefaultBoardConfig } from "datas/models/DefaultBoardConfig.m";
import { TeamProfile } from "datas/models/TeamProfile.m";
import { UserConfig } from "datas/models/User.m";
import { UserBoardConfig } from "datas/models/UserBoardConfig.m";
import { getHealthCheckDetails } from "datas/services/cgvHealth.service";
import { getUserTeamDefaultBoards } from "datas/services/defaultBoardConfigs";
import {
  getRequiredActions,
  REQUIRED_ACTION_TYPES,
} from "datas/services/requiredActions.service";
import { getAllOwnerSgBoards } from "datas/services/SgBoards";
import { getUserClosestTeamProfile } from "datas/services/teamConfigs";
import {
  getAllDatasets,
  getUserBoardConfigsId,
  getUserBoards as getUserPersonalBoards,
  getUserBoardsFromDB,
  putUserBoards,
} from "datas/services/userBoardConfigs";
import {
  getAllUsersConfigs,
  getUserConfig,
  updateUserConfig,
} from "datas/services/userConfig.service";
import datasSlice from "datas/store/datas.slice";
import { AllBoardsRef } from "datas/types/BoardConfig";
import { getUserBoardFromSGBoard } from "datas/utils/Boards";
import { getUserConfigFromDto } from "datas/utils/UserConfig.utils";
import _, { isArray } from "lodash";
import { authActions } from "modules/Authentication/store/auth.slice";
import UserBoardsEditor from "modules/UserBoardsEditor/components/UserBoardsEditor";
import { Dispatch } from "redux";
import homeSlice from "../store/home.slice";
import ApiMonitoringModal from "./ApiMonitoringModal";
import AutomateQuickCreateModal from "./AutomateQuickCreateModal";
import CloneModal from "./CloneModal";
import HomeBoards from "./HomeBoards";
import HomeHeader from "./HomeHeader";
import UserBoardsReminderModal from "./UserBoardsReminderModal";

export function userIsLoggedIn(isLoggedIn, token) {
  return isLoggedIn && _.isEmpty(token) === false;
}

const setCurrentBoard = (props, boards) => {
  if (boards && boards.length > 0) {
    props.setCurrentBoard(boards[0]);
  }
};

const mapStateToProps = (state: RootState) => ({
  app: { ...state.app },
  auth: { ...state.auth },
  home: { ...state.modules.home },
  datas: { ...state.datas },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setDefaultBoards: (boards: DefaultBoardConfig[]) =>
    dispatch(
      datasSlice.actions.updateAll({
        type: "defaultBoardConfigs",
        datas: boards,
      }),
    ),
  disableExportPdf: () => dispatch(homeSlice.actions.disableExportPdf()),
  setUserBoards: (boards: UserBoardConfig[]) =>
    dispatch(
      datasSlice.actions.updateAll({ type: "userBoardConfigs", datas: boards }),
    ),
  setAllUserSgBoards: (sgBoards: SgBoardsV3DTO) =>
    dispatch(
      datasSlice.actions.updateAll({
        type: "allUserSgBoards",
        datas: sgBoards,
      }),
    ),
  setTeamConfigs: (teamConfigs: TeamProfile[]) =>
    dispatch(
      datasSlice.actions.updateAll({ type: "teamConfigs", datas: teamConfigs }),
    ),
  setCurrentBoard: (board: DefaultBoardConfig | UserBoardConfig | undefined) =>
    dispatch(appSlice.actions.setCurrentBoard(board)),
  setUserOptionsConfig: (options: UserConfig) =>
    dispatch(authActions.setOptionsConfig(options)),
  setRequiredActionsNumber: (nb: number) =>
    dispatch(
      datasSlice.actions.updateAll({ type: "requiredActionsNb", datas: nb }),
    ),
  setHealthCheckDetails: (details: CgvHealthDetailsDto[]) =>
    dispatch(
      datasSlice.actions.updateAll({
        type: "healthCheckDetails",
        datas: details,
      }),
    ),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const HomeScreen: React.FC<Props> = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const boardsRef = React.useRef<AllBoardsRef>();
  const { user, token, loggedIn } = props.auth;
  const [isUserBoardsLoading, setIsUserBoardsLoading] = React.useState(true);

  const DEFAULT_USER_CONFIG: UserConfigDTO = {
    alwaysMirror: false,
    userBoardsReminderLastDate: getDateString(
      addDays(new Date(), -DAYS_TO_REMINDER),
    ),
    userId: user.id,
    boardsOrder: [],
  };

  function updateUserBoards(boards: UserBoardConfig[]) {
    putUserBoards(user.id, boards).then(() =>
      getUserBoardsFromDB(user).then((d) => props.setUserBoards(d)),
    );
  }

  const getTeamProfile = async () => {
    const tp = await getUserClosestTeamProfile(user?.team);
    props.setTeamConfigs(tp ? [tp] : []);
    return tp;
  };

  const getUserDefaultBoards = (teamProfile: TeamProfile) => {
    return getUserTeamDefaultBoards([teamProfile?.id]).then((teamBoards) => {
      props.setDefaultBoards(teamBoards);
      if (teamBoards.some((b) => b.dangerBadge === "REQUIRED_ACTIONS")) {
        getRequiredActionsNb();
      }
      return teamBoards;
    });
  };

  const getCurrentUserPersonalBoards = () => {
    //get from DB first
    return getUserBoardsFromDB(user)
      .then((userBoards) => {
        //fallback to lambda if no data
        if (userBoards === undefined) {
          getAllDatasets().then((datasets) => {
            const userBoardConfigId = getUserBoardConfigsId(user);
            const userHasPersonalDataset = datasets.some(
              (d) => d.id === userBoardConfigId,
            );
            if (userHasPersonalDataset) {
              return getUserPersonalBoards(user).then((userBoards) => {
                updateUserBoards(userBoards);
                return userBoards;
              });
            } else {
              updateUserBoards([]);
              return [] as UserBoardConfig[];
            }
          });
        } else {
          //continue with db retrieved
          props.setUserBoards(userBoards);
          return userBoards;
        }
      })
      .then(() => setIsUserBoardsLoading(false));
  };

  const getAllUserSgBoards = async () => {
    return getAllOwnerSgBoards();
  };

  const getRequiredActionsNb = () => {
    getRequiredActions(user).then((response) => {
      if (response !== undefined && response !== null) {
        let actionsCount = 0;
        for (const key of Object.keys(response)) {
          if (REQUIRED_ACTION_TYPES.includes(key) && isArray(response[key])) {
            actionsCount += response[key].length;
          }
        }
        props.setRequiredActionsNumber(actionsCount);
      }
    });
  };

  const getUserOptionsConfig = async (userId: string) => {
    //get first via DB
    return getUserConfig(userId).then((userConfDto) => {
      if (userConfDto === undefined) {
        //fallback to lambda
        return getAllUsersConfigs()
          .then((configs) => {
            const userConfig = configs.filter(
              (conf) => conf.userId === user.id,
            )[0];
            return userConfig ? userConfig : DEFAULT_USER_CONFIG;
          })
          .then((userConfigDto) => {
            updateUserConfig(props.auth.user.id, userConfigDto).then(() =>
              props.setUserOptionsConfig(userConfigDto),
            );
            return userConfigDto;
          });
      } else {
        //continue with db retrieved data
        props.setUserOptionsConfig(getUserConfigFromDto(userConfDto));
        return userConfDto;
      }
    });
  };

  const getHealthCheckDetailsFromCgv = () => {
    getHealthCheckDetails().then((response) => {
      props.setHealthCheckDetails(response);
    });
  };

  React.useEffect(() => {
    if (userIsLoggedIn(loggedIn, token)) {
      getTeamProfile()
        .then((teamProfile) => getUserDefaultBoards(teamProfile))
        .then((boards) => setCurrentBoard(props, boards))
        .then(() => setIsLoading(false))
        .then(() => getAllUserSgBoards())
        .then((sgBoardsDto) => {
          props.setAllUserSgBoards(sgBoardsDto);
          return sgBoardsDto;
        })
        .then(async (sgBoardsDto) => {
          return {
            allUserSgBoards: sgBoardsDto?.content,
            userConfigs: await getUserOptionsConfig(user.id),
          };
        })
        .then((data) => {
          if (data.allUserSgBoards && data.userConfigs?.alwaysMirror) {
            const allUserBoards = data.allUserSgBoards.map((sgBoard) => {
              return getUserBoardFromSGBoard(
                sgBoard.name,
                sgBoard.id.toString(),
                "",
                user.email,
                user.id,
              );
            });
            updateUserBoards(allUserBoards);
            setIsUserBoardsLoading(false);
          } else {
            getCurrentUserPersonalBoards();
          }
        });

      getHealthCheckDetailsFromCgv();
    }
  }, [loggedIn]);

  const postMessageHandler = (eventType: string, id?: number): void => {
    if (props.home.exportPdfDisabled) {
      console.debug("Export PDF already disabled");
    }
    props.disableExportPdf();
    const iframe = boardsRef.current?.currentBoard?.current?.iframe;
    if (iframe) {
      const getOrigin = () => {
        const env = boardsRef.current.currentBoard.current.env;
        return env === "uat" ? SG_DASHBOARD_UAT_ORIGIN : SG_DASHBOARD_ORIGIN;
      };
      const msg = { exportPdf360MsgType: eventType, id };
      iframe.contentWindow.postMessage(msg, getOrigin());
      console.debug(`Post PDF message at origin ${origin}`, msg);
    } else {
      console.warn("cant find iframe to post message PDF");
    }
  };

  return (
    <div className="h-100 d-flex flex-column">
      <HomeHeader onPostMessage={postMessageHandler} />
      {isLoading ? null : (
        <>
          <CloneModal />
          <UserBoardsReminderModal />
          <UserBoardsEditor />
          <AutomateQuickCreateModal />
          <ApiMonitoringModal />
          <HomeBoards
            ref={boardsRef}
            isUserBoardsLoading={isUserBoardsLoading}
          />
        </>
      )}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(HomeScreen);
