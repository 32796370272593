import ENDPOINTS from "common/constants/endpoints.const";
import { createRepository } from "common/utils/api/api";
import { fromJsonToObject } from "common/utils/api/api.helper";
import { TeamProfile } from "datas/models/TeamProfile.m";

function getUserDept(userTeam: string): string[] {
  return (userTeam ?? "").split("/");
}

function getDefaultconfigFromLambda(teamProfiles: TeamProfile[]): TeamProfile {
  return teamProfiles.filter((tp) => tp.id === "default")[0];
}

function calculateScore(userDepts: string[], configInclude: string[]): number {
  if (userDepts.length === configInclude.length) {
    return userDepts.length;
  } else {
    return configInclude.length;
  }
}

function getAllTeamProfiles(): Promise<TeamProfile[]> {
  return createRepository()
    .getStreamFromAPI(ENDPOINTS.TeamConfigs)
    .then((response) => (response?.ok ? response.text() : Promise.reject()))
    .then((responseText) => {
      return fromJsonToObject<TeamProfile[]>(responseText);
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export function getUserClosestTeamProfile(
  userTeam?: string,
): Promise<TeamProfile | undefined> {
  return getAllTeamProfiles().then((teamConfigs) => {
    const defaultConfig = getDefaultconfigFromLambda(teamConfigs);
    let bestTeamConfig: (TeamProfile & { score: number }) | undefined = {
      ...defaultConfig,
      score: 0,
    };
    const userDepts = getUserDept(userTeam);

    teamConfigs
      .filter((tc) => tc.id !== "default")
      .forEach((tc) => {
        if (
          tc.include.every((configInclude) => userDepts.includes(configInclude))
        ) {
          const currentScore = calculateScore(userDepts, tc.include);
          if (
            bestTeamConfig === undefined ||
            bestTeamConfig.score < currentScore
          ) {
            bestTeamConfig = { ...tc, score: currentScore };
          }
        }
      });

    return bestTeamConfig;
  });
}
