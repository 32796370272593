import * as React from "react";
import { ToastMessage } from "./toasterNotifyer";

export interface ToastProps {
  title: string;
  message: ToastMessage;
  detail?: string;
}

const ToastContent: React.FC<ToastProps> = ({ title, message, detail }) => (
  <>
    <div>
      <strong>{title}</strong>
    </div>
    <div>{message}</div>
    {detail && <div>{detail}</div>}
  </>
);

export default ToastContent;
