import { Meta } from "typescript-fsa";
import { HttpResquestHeader } from "../../../types/api";

export type Environment = "HOM" | "ISO" | "PRD";

export type CustomProperties = { [name: string]: string };

export enum Type {
  TrackPageData,
  ConsoleLog,
  ConsoleData,
  FeatureData,
  PerformanceData,
}

export enum Level {
  Trace,
  Debug,
  Info,
  Warn,
  Error,
  Fatal,
}

export interface LogEntry {
  type: Type;
  correlationId: string;
  name: string;
  message: string;
  criticity?: Level;
  executionTime?: number;
  data: CustomProperties;
  environment: Environment;
  timestamp: Date;
  application: string;
  component: string | undefined;
}

export interface BaseActionLog {
  description: string;
  event: string;
}

export interface ActionLog extends BaseActionLog {
  level?: Level;
  logType: Type;
}

export type RouterActionLog = BaseActionLog;

export type AsyncActionLog = BaseActionLog;

export type ActionLogMeta = AsyncActionLog | ActionLog | RouterActionLog;

export interface ActionMeta extends Meta {
  logger?: ActionLogMeta;
  headers?: HttpResquestHeader;
}

export enum AsyncActionTypes {
  started = "STARTED",
  done = "DONE",
  failed = "FAILED",
}

export interface LogMessage {
  name: string;
  type: Type;
  feature: string;
  event: string;
  description: string;
  level?: Level;
  watchKeyOrDuration?: number | string;
}
