import * as React from "react";
import { ComponentSize } from "@dcc-cli/ic-shared-components";
import { join } from "lodash";

interface IconProps {
  className?: string;
  size?: ComponentSize;
  onClick?: (e?: unknown) => void;
  occurences?: number;
  color?: string;
  toolTipText?: string;
  toolTipPlacement?: string;
}

export interface MDIconProps extends IconProps {
  iconName: string;
}

const generateIconClassName = (
  size?: ComponentSize,
  className?: string,
): string =>
  join(["icon", size ? `icon-${size}` : "", className || ""], " ").trim();

const Icon: React.FunctionComponent<MDIconProps> = (props: MDIconProps) => (
  <div
    style={{ display: "inline" }}
    data-toggle="tooltip"
    data-placement={props.toolTipPlacement || "bottom"}
    title={props.toolTipText}
  >
    <i
      role="button"
      tabIndex={0}
      className={generateIconClassName(props.size, props.className)}
      onClick={props.onClick}
      style={{ color: props.color, cursor: "inherit" }}
    >
      {props.iconName}
    </i>
    {props.occurences ? (
      <span style={{ position: "absolute" }}>{props.occurences}</span>
    ) : null}
  </div>
);

export const IconArrowBack = (props: IconProps) => (
  <Icon iconName={"arrow_back"} {...props} />
);

export const IconChevronLeft = (props: IconProps) => (
  <Icon iconName={"chevron_left"} {...props} />
);

export const IconChevronRight = (props: IconProps) => (
  <Icon iconName={"chevron_right"} {...props} />
);
